/* .headerWrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    /* background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.2),
        rgba(249, 250, 251, 0.2)
    ); 
    border-bottom: 1.5px solid rgba(226, 232, 240, 0.2);
    backdrop-filter: blur(0.5px);
    z-index: 10;
} */

.headerWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 5rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: linear-gradient(
        to top,
        rgba(243, 244, 246, 0),
        rgba(243, 244, 246, 0.85) 40%,
        rgba(229, 231, 235, 0.95)
    );
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    /* Add mask-image for fade effect (inverted direction from bottomBar) */
    -webkit-mask-image: linear-gradient(to top, transparent, black 40%, black);
    mask-image: linear-gradient(to top, transparent, black 40%, black);
    z-index: 10;
}

.headerLeft {
    display: flex;
    align-items: center;
    /* gap: 1rem; */
    /* padding: 0.8rem 0.2rem; */
    padding-top: 4px;
    padding-bottom: 0.1rem;
    padding-right: 5px;
}

.profileSection {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.5rem;
    border-radius: 1.5rem;
    transition: all 0.3s ease;
}

.profileImageWrapper {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: linear-gradient(135deg, #bfdbfe, #93c5fd);
    padding: 2px;
    animation: float 6s infinite ease-in-out;
    margin-top: -2px;
}

.profileBorder {
    position: absolute;
    inset: -4px;
    border-radius: 50%;
    background: linear-gradient(135deg, #60a5fa, #3b82f6, #2563eb, #3b82f6);
    opacity: 0.7;
    animation: spin 4s linear infinite;
}

.profileImage {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ff55d2;
    background: white;
}

.sparkleWrapper {
    position: absolute;
    top: -5px;
    right: -5px;
    background: #ed3bf6;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.8px solid white;
    box-shadow: 0 2px 4px rgba(59, 130, 246, 0.2);
}

.sparkleIcon {
    color: white;
    padding: 2px;
}

.usernameWrapper {
    position: absolute;
    right: 20px;
    background: linear-gradient(to bottom, #ffeffd, #fedbf7);
    padding: 8px 22px;
    border-radius: 2rem;
    padding-right: 42px;
    border: 2px solid #ff55d2;
    /* border-right: none; */
    box-shadow: 0 2px 4px rgba(59, 130, 246, 0.1);
}

.usernameWrapper span {
    margin: 0;
    padding: 0;
}

.username {
    font-family: 'Geist SemiBold';
    font-size: 0.9rem;
    color: #af1ea5;
    white-space: nowrap;
}

.miiButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.6rem 1.2rem;
    padding-left: 1.5rem;
    background: linear-gradient(to bottom, #f0f9ff, #e0f2fe);
    border: 2px solid #5697ff;
    /* border: none; */
    border-radius: 0 1rem 1rem 0rem;
    font-family: 'Geist SemiBold';
    color: #0284c7;
    transition: all 0.3s ease;
    border-left: none;
}

.miiButton svg {
    width: 1.15rem;
    height: 1.15rem;
}

.middle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.statusButton {
    display: flex;
    transform: translateX(50%);
    align-items: center;
    gap: 0.5rem;
    padding: 0.6rem 1.2rem;
    border-top: none;
    border-radius: 0rem 0rem 1rem 1rem;
    font-family: 'GeistMono Regular';
    font-size: 0.7rem;
    transition: all 0.3s ease;
    border-top: none;
}

.statusButton svg {
    width: 1rem;
    height: 1rem;
}

.statusButton.online {
    background: linear-gradient(to bottom, #f0fdf4, #dcfce7);
    border: 2px solid #86efac;
    color: #16a34a;
    border-top: none;
}

.statusButton.offline {
    background: linear-gradient(to bottom, #fef2f2, #fee2e2);
    border: 2px solid #fca5a5;
    color: #dc2626;
    border-top: none;
}

.statusPulse {
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerRight {
    /* padding-left: 1rem; */
    /* padding: 0.8rem 0rem; */
    padding-top: 10px;

    padding-bottom: 0.1rem;
    /* display: flex;
    align-items: center;
    justify-content: flex-end; */
}

@keyframes float {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-2px);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 640px) {
    /* .headerWrapper {
        padding: 0.5rem;
    } */

    /* .usernameWrapper {
        display: none;
    } */
}
