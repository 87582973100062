.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: repeating-linear-gradient(0deg, rgb(246, 247, 248) 0px, rgb(246, 247, 248) 2.5px, rgb(238, 237, 241) 2.5px, rgb(244, 245, 247) 3.2px, rgb(238, 237, 241) 3.8px, rgb(238, 237, 241) 3.9px);
}

.mapContainer {
  flex: 1;
  position: relative;
  margin: 1rem;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.95) 0%, rgba(246, 247, 248, 0.95) 100%);
  border-radius: 1.5rem;
  border: 1.4px solid rgb(215, 227, 231);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: grab;
  min-height: 100vh;
}

.mapContainer:active {
  cursor: grabbing;
}

.mapContent {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: center;
  will-change: transform;
  touch-action: none;
  user-select: none;
  padding: 200px;
  min-height: 200vh;
}

.controls {
  position: fixed;
  top: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
  z-index: 10;
}

.controlButton {
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  background: linear-gradient(to bottom, #ffffff, #f9fafb);
  border: 1px solid #e5e7eb;
  color: #374151;
  font-family: 'Geist Regular';
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.controlButton:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.controlButton:active {
  transform: scale(0.98);
}

.gateway {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;
  cursor: pointer;
  transform-origin: center center;
  padding: 8px;
  margin: -8px;
}

.gateway.selected {
  z-index: 3;
  transform: scale(1.2);
}

.gateway.dimmed {
  opacity: 0.4;
  filter: grayscale(1);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.gatewayIcon {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.95), rgba(243, 244, 246, 0.95));
  border: 2px solid rgba(147, 197, 253, 0.5);
  box-shadow:
    inset 0 2px 4px rgba(0, 0, 0, 0.05),
    0 2px 4px rgba(59, 130, 246, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.gatewayIcon::after {
  content: '';
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  background: radial-gradient(circle at center, rgba(96, 165, 250, 0.2), transparent 70%);
  opacity: 0;
  transition: opacity 0.3s;
}

.gateway:hover .gatewayIcon::after {
  opacity: 1;
}

.selected .gatewayIcon {
  border-color: #3b82f6;
  box-shadow: 0 0 30px rgba(59, 130, 246, 0.4);
}

.gatewayName {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -2rem;
  font-family: 'Geist Medium';
  font-size: 0.75rem;
  color: #3b82f6;
  background: rgba(255, 255, 255, 0.95);
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  border: 1px solid rgba(147, 197, 253, 0.5);
  white-space: nowrap;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.gateway:hover .gatewayName {
  transform: translateX(-50%) translateY(-2px);
  border-color: #93c5fd;
  box-shadow: 0 0 10px rgba(147, 197, 253, 0.3);
}

.selected .gatewayName {
  transform: translateX(-50%) translateY(-4px);
  border-color: #3b82f6;
  box-shadow: 0 0 15px rgba(59, 130, 246, 0.3);
}

.beacon {
  position: absolute;
  display: flex;
  align-items: center;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
  transform: scale(1);
  transform-origin: center center;
  padding: 6px;
  margin: -6px;
  cursor: pointer;
  z-index: 2;
  /* Add transition for position changes */
  transition:
    all 0.8s cubic-bezier(0.4, 0, 0.2, 1),
    left 0.8s cubic-bezier(0.4, 0, 0.2, 1),
    top 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.beacon.selected {
  transform: scale(1.2);
  z-index: 3;
}

.beacon.dimmed {
  opacity: 0.25;
  filter: grayscale(0.8);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.beaconIcon {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(243, 244, 246, 1));
  border: 2px solid rgba(147, 197, 253, 0.6);
  box-shadow:
    inset 0 2px 4px rgba(0, 0, 0, 0.1),
    0 2px 4px rgba(59, 130, 246, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1000;
}

.selected .beaconIcon {
  border-color: #3b82f6;
  box-shadow: 0 0 30px rgba(59, 130, 246, 0.4);
  animation: pulseGlow 2s infinite;
}

.beaconIcon::after {
  content: '';
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(115, 213, 255, 0.25), rgba(207, 239, 255, 0.25));
  animation: nintendoPulse 4s infinite;
  z-index: 0;
}

.beaconName {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -2rem;
  font-family: 'Geist Medium';
  font-size: 0.75rem;
  color: #3b82f6;
  background: rgba(255, 255, 255, 0.95);
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  border: 1px solid rgba(147, 197, 253, 0.5);
  white-space: nowrap;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: 3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.beacon:hover .beaconName,
.beacon.selected .beaconName {
  opacity: 1;
  transform: translateX(-50%) translateY(-2px);
  border-color: #93c5fd;
  box-shadow: 0 0 10px rgba(147, 197, 253, 0.3);
}

.selected .beaconName {
  transform: translateX(-50%) translateY(-4px);
  border-color: #3b82f6;
  box-shadow: 0 0 15px rgba(59, 130, 246, 0.3);
  font-size: 0.85rem;
  padding: 0.3rem 0.9rem;
}

.deviceImage {
  position: absolute;
  inset: 3px;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  object-fit: contain;
  border-radius: 50%;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  aspect-ratio: 1/1;
  z-index: 1;
}

.deviceIcon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  aspect-ratio: 1/1;
}
.connectionBeam {
  position: absolute;
  height: 6px;
  pointer-events: none;
  overflow: visible;
  border-radius: 3px;
  margin-top: -3px;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0.6;
}

.connectionBeam.hidden {
  opacity: 0.15;
  filter: grayscale(0.8);
}

/* When any beacon is selected, dim all connection beams significantly */
.beacon.selected ~ div .connectionBeam,
.beacon.dimmed ~ div .connectionBeam {
  opacity: 0.1;
  filter: grayscale(0.9);
}

/* But highlight the connections for the selected beacon */
.connectionBeam.highlighted {
  opacity: 1 !important;
  filter: none !important;
  z-index: 2;
}

.connectionBeam.highlighted::before {
  background: linear-gradient(120deg, rgba(96, 165, 250, 0.9), rgba(59, 130, 246, 1) 30%, rgba(37, 99, 235, 1) 70%, rgba(59, 130, 246, 1));
  animation: borderPulseHighlight 2s infinite;
  box-shadow: 0 0 20px rgba(59, 130, 246, 0.4);
}

.connectionBeam.highlighted::after {
  opacity: 0.9;
  animation: continuousShimmerHighlight 4s infinite;
}

.connectionBeam.highlighted .connectionDot {
  background: radial-gradient(circle at center, rgba(255, 255, 255, 1) 0%, rgba(96, 165, 250, 1) 40%, rgba(96, 165, 250, 0) 100%);
  filter: drop-shadow(0 0 8px rgba(96, 165, 250, 1));
  animation: nintendoDotTravelHighlight 1.5s infinite cubic-bezier(0.4, 0, 0.2, 1);
}
.connectionBeam::before {
  content: '';
  position: absolute;
  inset: 0;
  padding: 2px;
  border-radius: 3px;
  background: linear-gradient(120deg, rgba(96, 165, 250, 0.6), rgba(59, 130, 246, 0.8) 30%, rgba(37, 99, 235, 0.9) 70%, rgba(59, 130, 246, 0.8));
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: borderPulse 8s infinite;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.connectionBeam::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(120deg, #93c5fd 0%, #60a5fa 45%, #dbeafe 50%, #60a5fa 55%, #3b82f6 100%);
  border-radius: 3px;
  background-size: 200% 100%;
  animation: continuousShimmer 8s infinite;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.connectionDots {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: -3px;
}

.connectionDot {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  filter: blur(0.5px);
  animation: nintendoDotTravel 2s infinite cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(-50%);
}

.hidden {
  opacity: 0.15;
  filter: grayscale(0.8);
  pointer-events: none;
}

.sparkleContainer {
  position: absolute;
  inset: -20px;
  pointer-events: none;
  z-index: 0;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.beacon:hover .sparkleContainer,
.gateway:hover .sparkleContainer {
  opacity: 1;
}

.sparkle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: linear-gradient(135deg, #93c5fd, #3b82f6);
  animation: nintendoSparkle 1.5s infinite;
  filter: drop-shadow(0 0 4px rgba(59, 130, 246, 0.7));
}

.sparkle:nth-child(1) {
  top: 20%;
  left: 10%;
  animation-delay: 0s;
}
.sparkle:nth-child(2) {
  top: 60%;
  right: 10%;
  animation-delay: 0.375s;
}
.sparkle:nth-child(3) {
  bottom: 20%;
  left: 15%;
  animation-delay: 0.75s;
}
.sparkle:nth-child(4) {
  top: 30%;
  right: 15%;
  animation-delay: 1.125s;
}

/* Mobile Optimizations */
@media (max-width: 768px) {
  .mapContainer {
    margin: 0.5rem;
  }

  .controls {
    top: auto;
    bottom: 1.5rem;
    right: 1.5rem;
    flex-direction: column;
    gap: 0.75rem;
  }

  .controlButton {
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    min-width: 120px;
  }

  .gatewayIcon {
    width: 52px;
    height: 52px;
  }

  .beaconIcon {
    width: 44px;
    height: 44px;
  }

  .selected .beacon .beaconIcon {
    width: 56px;
    height: 56px;
  }

  .gateway.selected {
    transform: scale(1.5);
  }

  .gatewayName,
  .beaconName {
    font-size: 0.8rem;
    padding: 0.3rem 0.75rem;
    top: -1.75rem;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .selected .gatewayName {
    transform: translateX(-50%) translateY(-2px) scale(0.8);
  }

  .selected .beacon .beaconName {
    font-size: 0.9rem;
    padding: 0.35rem 1rem;
  }

  .connectionBeam {
    height: 5px;
    margin-top: -2.5px;
  }
}

@media (max-width: 480px) {
  .mapContainer {
    margin: 0.25rem;
  }

  .controls {
    right: 1rem;
    bottom: 1rem;
    gap: 0.5rem;
  }

  .controlButton {
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
  }

  .gatewayIcon {
    width: 48px;
    height: 48px;
  }

  .beaconIcon {
    width: 40px;
    height: 40px;
  }

  .selected .beacon .beaconIcon {
    width: 52px;
    height: 52px;
  }

  .gateway.selected {
    transform: scale(1.3);
  }

  .gatewayName,
  .beaconName {
    font-size: 0.75rem;
    padding: 0.25rem 0.6rem;
    top: -1.5rem;
  }

  .selected .gatewayName {
    transform: translateX(-50%) translateY(-2px) scale(0.9);
  }

  .selected .beacon .beaconName {
    font-size: 0.85rem;
    padding: 0.3rem 0.9rem;
  }

  .connectionBeam {
    height: 4px;
    margin-top: -2px;
  }

  .connectionDot {
    width: 6px;
    height: 6px;
  }
}

/* Signal strength variations */
.connectionBeam[data-strength='strong']::after {
  opacity: 0.4;
}

.connectionBeam[data-strength='strong']::before {
  background: linear-gradient(120deg, rgba(96, 165, 250, 0.8), rgba(59, 130, 246, 0.9) 30%, rgba(37, 99, 235, 1) 70%, rgba(59, 130, 246, 0.9));
}

.connectionBeam[data-strength='strong'] .connectionDot {
  background: radial-gradient(circle at center, rgba(255, 255, 255, 1) 0%, rgba(96, 165, 250, 1) 40%, rgba(96, 165, 250, 0) 100%);
  filter: drop-shadow(0 0 5px rgba(96, 165, 250, 0.8));
}

.connectionBeam[data-strength='medium']::after {
  opacity: 0.3;
}

.connectionBeam[data-strength='medium']::before {
  background: linear-gradient(120deg, rgba(96, 165, 250, 0.6), rgba(59, 130, 246, 0.8) 30%, rgba(37, 99, 235, 0.9) 70%, rgba(59, 130, 246, 0.8));
}

.connectionBeam[data-strength='medium'] .connectionDot {
  background: radial-gradient(circle at center, rgba(255, 255, 255, 1) 0%, rgba(96, 165, 250, 0.8) 40%, rgba(96, 165, 250, 0) 100%);
  filter: drop-shadow(0 0 4px rgba(96, 165, 250, 0.6));
}

.connectionBeam[data-strength='weak']::after {
  opacity: 0.2;
}

.connectionBeam[data-strength='weak']::before {
  background: linear-gradient(120deg, rgba(96, 165, 250, 0.4), rgba(59, 130, 246, 0.6) 30%, rgba(37, 99, 235, 0.7) 70%, rgba(59, 130, 246, 0.6));
}

.connectionBeam[data-strength='weak'] .connectionDot {
  background: radial-gradient(circle at center, rgba(255, 255, 255, 1) 0%, rgba(96, 165, 250, 0.6) 40%, rgba(96, 165, 250, 0) 100%);
  filter: drop-shadow(0 0 3px rgba(96, 165, 250, 0.4));
}

/* Animations */
@keyframes borderPulse {
  0%,
  100% {
    opacity: 1;
    filter: brightness(1);
  }
  50% {
    opacity: 0.8;
    filter: brightness(1.2);
  }
}
@keyframes borderPulseHighlight {
  0%,
  100% {
    opacity: 1;
    filter: brightness(1.3);
  }
  50% {
    opacity: 0.95;
    filter: brightness(1.8);
  }
}

@keyframes continuousShimmerHighlight {
  0% {
    background-position: 200% 0;
    filter: brightness(1.3);
  }
  100% {
    background-position: -200% 0;
    filter: brightness(1.3);
  }
}

@keyframes nintendoDotTravelHighlight {
  0% {
    opacity: 0;
    transform: translate(0, -50%) scale(1);
  }
  20% {
    opacity: 1;
    transform: translate(20%, -50%) scale(1.8);
  }
  80% {
    opacity: 1;
    transform: translate(80%, -50%) scale(1.8);
  }
  100% {
    opacity: 0;
    transform: translate(100%, -50%) scale(1);
  }
}

/* Update weak connection styles to be even dimmer when not highlighted */
.weakConnection::before {
  background: linear-gradient(120deg, rgba(96, 165, 250, 0.1), rgba(59, 130, 246, 0.2) 30%, rgba(37, 99, 235, 0.3) 70%, rgba(59, 130, 246, 0.2)) !important;
}

.weakConnection::after {
  opacity: 0.05 !important;
}

.weakConnection .connectionDot {
  display: none;
}

/* Override signal strength variations for weak connections */
.weakConnection[data-strength='strong']::after,
.weakConnection[data-strength='medium']::after,
.weakConnection[data-strength='weak']::after {
  opacity: 0.05 !important;
}

.weakConnection[data-strength='strong']::before,
.weakConnection[data-strength='medium']::before,
.weakConnection[data-strength='weak']::before {
  background: linear-gradient(120deg, rgba(96, 165, 250, 0.1), rgba(59, 130, 246, 0.2) 30%, rgba(37, 99, 235, 0.3) 70%, rgba(59, 130, 246, 0.2)) !important;
}

/* But allow highlighted weak connections to be more visible */
.weakConnection.highlighted::before {
  background: linear-gradient(120deg, rgba(96, 165, 250, 0.7), rgba(59, 130, 246, 0.8) 30%, rgba(37, 99, 235, 0.9) 70%, rgba(59, 130, 246, 0.8)) !important;
}

.weakConnection.highlighted::after {
  opacity: 0.4 !important;
}

@keyframes nintendoPulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}

@keyframes nintendoSparkle {
  0%,
  100% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  25% {
    transform: scale(1.2) rotate(90deg);
    opacity: 1;
  }
  75% {
    transform: scale(1.4) rotate(270deg);
    opacity: 0.8;
  }
}

@keyframes pulseGlow {
  0%,
  100% {
    box-shadow: 0 0 30px rgba(59, 130, 246, 0.4);
  }
  50% {
    box-shadow: 0 0 50px rgba(59, 130, 246, 0.6);
  }
}

/* Add new animation for semi-circle transition */
@keyframes semiFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(20px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

/* Apply semi-circle animation to beacons when gateway is selected */
.gateway.selected ~ div .beacon {
  animation: semiFadeIn 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}
