.container {
  min-height: 100vh;
  background-image: repeating-linear-gradient(0deg, rgb(246, 247, 248) 0px, rgb(246, 247, 248) 2.5px, rgb(238, 237, 241) 2.5px, rgb(244, 245, 247) 3.2px, rgb(238, 237, 241) 3.8px, rgb(238, 237, 241) 3.9px);
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Geist Regular';
  padding: 1rem;
}

/* Top Navigation */
.topNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.backButton,
.editButton,
.qrButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  transition: all 0.3s;
  font-weight: 500;
}

.backButton {
  background: linear-gradient(to bottom, #ffffff, #f9fafb);
  border: 1px solid #e5e7eb;
  color: #374151;
}

.editButton {
  background: linear-gradient(to bottom, #3b82f6, #2563eb);
  border: none;
  color: white;
}

.qrButton {
  background: linear-gradient(to bottom, #3b82f6, #2563eb);
  border: none;
  color: white;
  gap: 0;
}

.backIcon {
  transition: transform 0.3s;
}

.backButton:hover .backIcon {
  transform: translateX(-4px);
}

.editIcon {
  transition: transform 0.3s;
}

.qrIcon {
  transition: transform 0.3s;
}

.editButton:hover .editIcon {
  transform: rotate(12deg);
}

.qrButton:hover .qrIcon {
  transform: rotate(12deg);
}

/* Safe area adjustments */
@supports (padding: max(0px)) {
  .container {
    padding-top: max(1rem, env(safe-area-inset-top));
    padding-bottom: max(1rem, env(safe-area-inset-bottom));
    padding-left: max(1rem, env(safe-area-inset-left));
    padding-right: max(1rem, env(safe-area-inset-right));
  }
}

/* Enhanced button effects */
.editButton::after,
.backButton::after {
  content: '';
  position: absolute;
  inset: -2px;
  border-radius: inherit;
  background: linear-gradient(45deg, rgba(59, 130, 246, 0.2), rgba(37, 99, 235, 0.2));
  opacity: 0;
  transition: opacity 0.3s;
  z-index: -1;
}

.editButton:hover::after,
.backButton:hover::after {
  opacity: 1;
}

.backButton:hover,
.editButton:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Touch Device Optimizations */
@media (hover: none) {
  .backButton:active,
  .editButton:active {
    transform: scale(0.98);
  }
}
