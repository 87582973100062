/* Device Header */
.deviceHeader {
  position: relative;
  padding: 1.4rem 1rem;
  padding-bottom: 16px;
  border-radius: 1.5rem;
  margin-bottom: 18px;
  overflow: hidden;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.95) 0%, rgba(246, 247, 248, 0.95) 100%);
  isolation: isolate; /* Create new stacking context */
}

/* Sparkles - lowest layer */
.sparkleContainer {
  position: absolute;
  inset: -20px;
  pointer-events: none;
  z-index: 0;
}

.sparkle {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #60a5fa;
}

.sparkle:nth-child(1) {
  top: 20%;
  left: 10%;
  animation: continuousSparkle 2s infinite 0s;
}

.sparkle:nth-child(2) {
  top: 60%;
  right: 10%;
  animation: continuousSparkle 2s infinite 0.3s;
}

.sparkle:nth-child(3) {
  bottom: 20%;
  left: 15%;
  animation: continuousSparkle 2s infinite 0.6s;
}

.sparkle:nth-child(4) {
  top: 30%;
  right: 15%;
  animation: continuousSparkle 2s infinite 0.9s;
}

/* Gradient border - middle layer */
.deviceHeader::before {
  content: '';
  position: absolute;
  inset: 0;
  padding: 2px;
  border-radius: 1.5rem;
  background: linear-gradient(120deg, #93c5fd 0%, #60a5fa 45%, #dbeafe 50%, #60a5fa 55%, #3b82f6 100%);
  background-size: 400% 100%;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: shimmerBorder 6s linear infinite;
  z-index: 1;
}

/* Content - highest layer */
.deviceHeaderContent {
  position: relative;
  display: flex;
  gap: 0.5rem;
  z-index: 2;
}

.deviceImageContainer {
  position: relative;
  padding-left: 0.4rem;
}

.deviceImageWrapper {
  position: relative;
  height: 70px;
  width: 70px;
  margin-bottom: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  border-radius: 50%;
  background: linear-gradient(to bottom right, rgb(229, 236, 245), rgb(209, 224, 244));
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  animation: gentleFloat 4s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.deviceImageWrapper::before {
  content: '';
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
  box-shadow:
    inset 0 2px 4px rgba(0, 0, 0, 0.05),
    0 2px 4px rgba(59, 130, 246, 0.1);
  z-index: -1;
}

.deviceImage {
  width: 3rem;
  height: 3rem;
  object-fit: contain;
  border-radius: 50%;
}

.statusIndicator {
  position: absolute;
  bottom: 0.25rem;
  right: -0.25rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.online {
  background: linear-gradient(45deg, #10b981, #34d399);
  animation: glow 2s infinite;
}

.offline {
  background: linear-gradient(45deg, #9ca3af, #d1d5db);
}

.deviceInfo {
  flex: 1;
  position: relative;
}

.deviceName {
  font-size: 1.2rem;
  font-weight: 700;
  color: #1f2937;
  margin-bottom: 0.35rem;
  margin-top: -0.2rem;
}

.deviceMac {
  font-family: 'GeistMono Regular';
  font-size: 0.875rem;
  color: #6b7280;
}

.deviceModel {
  font-size: 0.875rem;
  color: #4b5563;
  margin-top: 0.35rem;
  margin-bottom: 0rem;
}

.deviceTypeBadge {
  position: absolute;
  top: -0.75rem;
  right: 0rem;
  padding: 0.4rem 0.75rem;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(248, 250, 255, 0.9));
  border-radius: 1rem;
  font-family: 'GeistMono Regular';
  font-size: 0.75rem;
  color: #3b82f6;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(59, 130, 246, 0.2);
  z-index: 3;
}

.deviceTypeBadge svg {
  width: 14px;
  height: 14px;
}

/* Linked Items */
.metaCard {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem 1.2rem;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.95) 0%, rgba(246, 247, 248, 0.95) 100%);
  border-radius: 1rem;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.02),
    0 4px 8px rgba(0, 0, 0, 0.02);
  animation: cardAppear 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  z-index: 2;
}

.linkedItemMeta::before {
  content: '';
  position: absolute;
  inset: 0;
  padding: 1.4px;
  border-radius: 1rem;
  background: linear-gradient(135deg, rgba(60, 159, 251, 0.6), rgba(22, 135, 249, 0.7) 30%, rgba(12, 103, 194, 0.8) 70%, rgba(22, 147, 249, 0.7));
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: borderPulse 8s infinite 6s;
}

.linkedNameMeta::before {
  content: '';
  position: absolute;
  inset: 0;
  padding: 1.4px;
  border-radius: 1rem;
  background: linear-gradient(135deg, rgba(251, 60, 251, 0.6), rgba(226, 22, 249, 0.7) 30%, rgba(191, 12, 194, 0.8) 70%, rgba(249, 22, 245, 0.7));
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: borderPulse 8s infinite 6s;
}

.metaIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  margin-right: 0.8rem;
  background: linear-gradient(135deg, rgba(245, 245, 245, 0.95), rgba(236, 237, 238, 0.9));
}

.metaLabel {
  font-family: 'Geist Regular';
  font-size: 12px;
  color: #64748b;
  margin-bottom: 0.35rem;
}

.metaValue {
  font-family: 'Geist Regular';
  font-size: 14px;
  color: #1e293b;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Animations */
@keyframes shimmerBorder {
  0% {
    background-position: 200% center;
  }
  100% {
    background-position: -200% center;
  }
}

@keyframes borderPulse {
  0%,
  100% {
    opacity: 1;
    filter: brightness(1);
  }
  50% {
    opacity: 0.8;
    filter: brightness(1.2);
  }
}

@keyframes gentleFloat {
  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-6px) rotate(1.5deg);
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0 0 rgba(16, 185, 129, 0.4);
  }
  50% {
    box-shadow: 0 0 8px 4px rgba(16, 185, 129, 0.2);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(16, 185, 129, 0);
  }
}

@keyframes continuousSparkle {
  0%,
  100% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: scale(1) rotate(180deg);
    opacity: 0.8;
  }
}

@keyframes cardAppear {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive */
@media (max-width: 640px) {
  .deviceHeaderContent {
    text-align: left;
    align-items: center;
  }

  .deviceInfo {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-left: 1rem;
  }
}
