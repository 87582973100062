.locationCard {
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  border: 1px solid #f3f4f6;
}

.locationTitle {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.locationIcon {
  color: #3b82f6;
}

.locationGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.locationDetail {
  padding: 1rem;
  background: #f9fafb;
  border-radius: 0.75rem;
  transition: all 0.3s ease;
}

.locationDetail:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.detailLabel {
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 0.25rem;
}

.detailValue {
  font-family: 'GeistMono Regular';
  font-size: 0.875rem;
}

.mapPlaceholder {
  grid-column: span 2;
  height: 12rem;
  background: #eff6ff;
  border: 1px solid #bfdbfe;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3b82f6;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.mapPlaceholder::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(45deg, rgba(59, 130, 246, 0.1) 0%, rgba(37, 99, 235, 0.1) 100%);
  opacity: 0;
  transition: opacity 0.3s;
}

.mapPlaceholder:hover::before {
  opacity: 1;
}

/* Responsive Adjustments */
@media (max-width: 640px) {
  .locationGrid {
    grid-template-columns: 1fr;
  }

  .mapPlaceholder {
    grid-column: span 1;
  }
}
