.timelineCard {
  background: white;
  border-radius: 1.5rem;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  border: 1px solid #f3f4f6;
  margin-top: 1.5rem;
}

.timelineHeader {
  width: 100%;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to bottom, white, #fafafa);
  border: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.timelineHeader:hover {
  background: linear-gradient(to bottom, #f8f9ff, #f0f4ff);
}

.timelineTitle {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-family: 'Geist Medium';
  font-size: 1.125rem;
  color: #1f2937;
}

.timelineTitle h2 {
  font-size: 20px;
  margin: 0;
}

.timelineIcon {
  color: #3b82f6;
  animation: pulse 2s infinite;
}

.expandIcon {
  color: #6b7280;
  transition: transform 0.2s ease;
}

.timelineContent {
  padding: 1.5rem;
  overflow: hidden;
}

/* View Toggle */
.viewToggle {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  padding: 0.25rem;
  background: #f3f4f6;
  border-radius: 0.75rem;
}

.viewButton {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  background: transparent;
  color: #6b7280;
  font-family: 'Geist Regular';
  transition: all 0.2s;
  cursor: pointer;
}

.viewButtonActive {
  background: white;
  color: #3b82f6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Timeline View */
.timeline {
  position: relative;
  padding-left: 2rem;
}

.timeline::before {
  content: '';
  position: absolute;
  left: 0.85rem;
  top: 0;
  bottom: 0;
  width: 2px;
  background: linear-gradient(to bottom, #bfdbfe 0%, #93c5fd 15%, #60a5fa 50%, #93c5fd 85%, #bfdbfe 100%);
  animation: shimmer 3s infinite linear;
  background-size: 200% 200%;
}

.timelineItem {
  position: relative;
  margin-bottom: 1.5rem;
  padding-left: 1rem;
}

.timelineDot {
  position: absolute;
  left: -1.5rem;
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #60a5fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: bounce 2s infinite;
}

.timelineItemContent {
  background: linear-gradient(to bottom right, #f8faff, #f0f4ff);
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid #e5e7eb;
  transition: all 0.3s ease;
}

.timelineItemHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.timelineTime {
  font-family: 'GeistMono Regular';
  font-size: 0.875rem;
  color: #4b5563;
}

.proximityBadge {
  font-family: 'Geist Regular';
  font-size: 0.75rem;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-weight: 500;
  text-transform: capitalize;
}

.signalMetrics {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.signalWrapper,
.distanceWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.signalLabel,
.distanceLabel {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  font-family: 'Geist Regular';
  font-size: 0.875rem;
  color: #4b5563;
}

.signalLabel svg,
.distanceLabel svg {
  color: #3b82f6;
}

.signalBarContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.signalValue,
.distanceValue {
  font-family: 'GeistMono Regular';
  font-size: 0.875rem;
  color: #374151;
  min-width: 4rem;
}

.signalBar {
  flex: 1;
  height: 6px;
  background: #e5e7eb;
  border-radius: 3px;
  overflow: hidden;
}

.signalBarFill {
  height: 100%;
  border-radius: 3px;
  transition: width 0.3s ease;
}

/* Chart View */
.signalChart {
  padding: 1rem;
  background: white;
  border-radius: 1.25rem;
  margin-top: 1rem;
}

.mobileChartView {
  padding: 1.5rem 1rem;
}

.currentSignal {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
}

.signalRing {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #f8faff, #f0f4ff);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:
    0 4px 12px rgba(0, 0, 0, 0.05),
    inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.signalIndicator {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  animation: pulse 2s infinite;
}

.signalIcon {
  color: white;
}

.signalValue {
  position: absolute;
  bottom: -3rem;
  text-align: center;
  width: 100%;
}

.signalStrength {
  font-family: 'GeistMono Regular';
  font-size: 1.25rem;
  color: #1e293b;
  margin-bottom: 0.25rem;
}

.signalLabel {
  font-family: 'Geist Regular';
  font-size: 0.875rem;
  text-align: center;
  display: flex;
  justify-content: center;
  color: #64748b;
}

/* Signal History */
.signalHistory {
  margin-top: 4rem;
  padding-top: 1rem;
  border-top: 1px solid #e2e8f0;
}

.historyTitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Geist Medium';
  color: #1e293b;
  margin-bottom: 1rem;
  padding: 0 0.5rem;
}

.historyGrid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.historyItem {
  display: grid;
  grid-template-columns: 4rem 1fr 4rem;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  border-radius: 0.75rem;
  background: linear-gradient(to right, #f8faff, #f0f4ff);
  transition: transform 0.2s ease;
}

.historyItem:hover {
  transform: translateX(4px);
}

.historyTime {
  font-family: 'GeistMono Regular';
  font-size: 0.75rem;
  color: #64748b;
}

.historyBar {
  height: 8px;
  background: #e2e8f0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.historyBar::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: var(--strength);
  background: var(--color);
  border-radius: 4px;
  transition: width 0.3s ease;
}

.historyValue {
  font-family: 'GeistMono Regular';
  font-size: 0.75rem;
  color: #1e293b;
  text-align: right;
}

/* Signal Quality Summary */
.signalSummary {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e5e7eb;
}

.summaryCard {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: linear-gradient(to bottom right, #f8faff, #f0f4ff);
  border-radius: 1rem;
  border: 1px solid #e5e7eb;
}

.summaryIcon {
  padding: 0.75rem;
  background: white;
  border-radius: 0.75rem;
  color: #3b82f6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.summaryInfo {
  flex: 1;
}

.summaryLabel {
  font-family: 'Geist Regular';
  font-size: 0.875rem;
  color: #6b7280;
}

.summaryValue {
  font-family: 'GeistMono Regular';
  font-size: 1rem;
  color: #1f2937;
  margin-top: 0.25rem;
}

/* Animations */
@keyframes shimmer {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 200%;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Mobile Optimizations */
@media (max-width: 640px) {
  .timelineContent {
    padding: 1rem;
  }

  .timeline {
    padding-left: 1.5rem;
  }

  .timelineDot {
    width: 24px;
    height: 24px;
    left: -1.5rem;
  }

  .timelineItemContent {
    padding: 0.75rem;
  }

  .signalBarContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .signalBar {
    width: 100%;
    margin-top: 0.25rem;
  }

  .signalValue {
    min-width: auto;
  }

  /* Prevent text overflow on mobile */
  .timelineTime,
  .proximityBadge,
  .signalValue,
  .distanceValue {
    white-space: nowrap;
  }
}

/* Touch device optimizations */
@media (hover: none) {
  .timelineItemContent:active {
    transform: translateX(2px);
  }

  .timelineHeader:active {
    background: linear-gradient(to bottom, #f0f4ff, #e5e7ff);
  }

  .viewButton:active {
    opacity: 0.8;
  }

  .historyItem:active {
    transform: translateX(2px);
  }
}

/* Accessibility */
.viewButton:focus-visible {
  outline: 2px solid #3b82f6;
  outline-offset: 2px;
}
