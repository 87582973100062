/* Base stats grid styles */
.statsGridMobile {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem;
  padding: 0.5rem;
}

@media (min-width: 480px) {
  .statsGridMobile {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Stat Card Base */
.statCard {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.95) 0%, rgba(246, 247, 248, 0.95) 100%);
  border-radius: 1.25rem;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.02),
    0 4px 8px rgba(0, 0, 0, 0.02);
  animation: cardAppear 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* Gradient Borders */
.locationStat::before {
  content: '';
  position: absolute;
  inset: 0;
  padding: 3px;
  border-radius: 1.25rem;
  background: linear-gradient(135deg, rgba(96, 165, 250, 0.6), rgba(59, 130, 246, 0.8) 30%, rgba(37, 99, 235, 0.9) 70%, rgba(59, 130, 246, 0.8));
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: borderPulse 8s infinite;
}

.statusStat::before {
  content: '';
  position: absolute;
  inset: 0;
  padding: 3px;
  border-radius: 1.25rem;
  background: linear-gradient(135deg, rgba(52, 211, 153, 0.6), rgba(16, 185, 129, 0.8) 30%, rgba(4, 120, 87, 0.9) 70%, rgba(16, 185, 129, 0.8));
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: borderPulse 8s infinite 2s;
}

.timeStat::before {
  content: '';
  position: absolute;
  inset: 0;
  padding: 3px;
  border-radius: 1.25rem;
  background: linear-gradient(135deg, rgba(167, 139, 250, 0.6), rgba(139, 92, 246, 0.8) 30%, rgba(109, 40, 217, 0.9) 70%, rgba(139, 92, 246, 0.8));
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: borderPulse 8s infinite 4s;
}

.signalStat::before {
  content: '';
  position: absolute;
  inset: 0;
  padding: 3px;
  border-radius: 1.25rem;
  background: linear-gradient(135deg, rgba(251, 146, 60, 0.6), rgba(249, 115, 22, 0.8) 30%, rgba(194, 65, 12, 0.9) 70%, rgba(249, 115, 22, 0.8));
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: borderPulse 8s infinite 6s;
}

/* Sequential animations */
.locationStat {
  animation: sequentialBounce 16s infinite;
}

.statusStat {
  animation: sequentialBounce 16s infinite 4s;
}

.timeStat {
  animation: sequentialBounce 16s infinite 8s;
}

.signalStat {
  animation: sequentialBounce 16s infinite 12s;
}

/* Icon styling */
.statIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1rem;
  margin-right: 1rem;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.95), rgba(246, 247, 248, 0.9));
  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.05),
    inset 0 1px 1px rgba(255, 255, 255, 0.8);
}

.locationStat .statIcon {
  color: #3b82f6;
}

.statusStat .statIcon {
  color: #10b981;
}

.timeStat .statIcon {
  color: #8b5cf6;
}

.signalStat .statIcon {
  color: #f97316;
}

/* Content styling */
.statContent {
  flex: 1;
}

.statLabel {
  font-family: 'Geist Regular';
  font-size: 0.875rem;
  color: #64748b;
  margin-bottom: 0.25rem;
}

.statValue {
  font-family: 'GeistMono Regular';
  font-size: 16px;
  color: #1e293b;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Status dot */
.statusDot {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}

.statusDot.online {
  background: #10b981;
  box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.2);
  animation: pulse 2s infinite;
}

.statusDot.offline {
  background: #94a3b8;
  box-shadow: 0 0 0 2px rgba(148, 163, 184, 0.2);
}

/* Loading state */
.isLoading {
  position: relative;
  background: linear-gradient(90deg, rgba(251, 252, 253, 0.95) 0%, rgba(243, 244, 246, 0.9) 25%, #bfdbfe 50%, rgba(243, 244, 246, 0.9) 75%, rgba(251, 252, 253, 0.95) 100%);
  background-size: 200% 100%;
  animation: shimmerLoading 2s linear infinite;
}

.loadingSparkles {
  position: absolute;
  inset: 0;
  pointer-events: none;
}

.loadingSparkle {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(96, 165, 250, 0.6);
  animation: sparkleFloat 1s infinite;
}

/* Animations */
@keyframes cardAppear {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes borderPulse {
  0%,
  100% {
    opacity: 1;
    filter: brightness(1);
  }
  50% {
    opacity: 0.8;
    filter: brightness(1.2);
  }
}

@keyframes sequentialBounce {
  0%,
  95%,
  100% {
    transform: translateY(0);
  }
  97.5% {
    transform: translateY(-4px);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(16, 185, 129, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(16, 185, 129, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(16, 185, 129, 0);
  }
}

@keyframes shimmerLoading {
  0% {
    background-position: -200% center;
  }
  100% {
    background-position: 200% center;
  }
}

@keyframes sparkleFloat {
  0%,
  100% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 0.2;
  }
  50% {
    transform: translate(6px, -3px) rotate(180deg);
    opacity: 0.8;
  }
}

/* Hover effects */
.statCard:hover {
  transform: translateY(-2px);
}

.statCard:hover::before {
  padding: 3.5px;
  filter: brightness(1.1);
}

/* Touch device optimizations */
@media (hover: none) {
  .statCard:active {
    transform: scale(0.98);
  }

  .statCard:active::before {
    filter: brightness(1.05);
    padding: 3px;
  }
}

/* Reduced motion */
@media (prefers-reduced-motion: reduce) {
  .statCard,
  .locationStat,
  .statusStat,
  .timeStat,
  .signalStat {
    animation: none;
  }
}

/* Additional styles */
.rxName {
  font-family: 'GeistMono Regular';
  font-size: 10px;
  color: #fff;
  background-color: rgba(139, 92, 246, 0.8);
  padding: 0.15rem 0.4rem;
  margin-left: 0.25rem;
}
