.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.modalContent {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  width: 400px;
  text-align: center;
}

.modalContent h2 {
  margin-bottom: 1.5rem;
  color: #1f2937;
  font-family: 'Geist Medium';
}

.qrCodeContainer {
  background: #f9fafb;
  padding: 1.5rem;
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
}

.qrCodeContainer img {
  max-width: 100%;
  height: auto;
}

.downloadButton {
  background: linear-gradient(to bottom, #3b82f6, #2563eb);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  border: none;
  font-weight: 500;
  transition: all 0.3s;
  cursor: pointer;
}

.downloadButton:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.downloadButton:active {
  transform: translateY(0);
}
