@font-face {
    font-family: 'Geist SemiBold';
    src: url('fonts/gi/Geist-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

.loadingScreen {
    cursor: url('fonts/wii-hand-loading.cur'), auto;
    position: fixed;
    inset: 0;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
}

.placeholderGrid {
    display: grid;
    gap: 0.74rem;
    grid-template-columns: repeat(24, 4fr);
    padding: 1rem;
    width: 100%;
    margin-left: 20px;
    /* max-width: 42rem; */
}

.placeholderCard {
    position: relative;
    aspect-ratio: 1;
    background: rgb(27, 27, 27);
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2);
    will-change: opacity, transform;
    width: 78px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.placeholderCard p {
    font-family: 'Geist SemiBold';
    letter-spacing: 0.5px;
    font-size: 14px;
}

/* Glow effect */
.placeholderCard::after {
    content: '';
    position: absolute;
    inset: -2px;
    background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
    border-radius: 1.75rem;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.2s ease;
}

/* Glow appears when card is highlighted */
.placeholderCard[style*='opacity: 1']::after {
    opacity: 0.3;
}
