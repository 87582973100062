/* Font declarations with proper font-display */
@font-face {
  font-family: 'Geist Regular';
  src: url('fonts/gi/Geist-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geist Bold';
  src: url('fonts/gi/Geist-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geist Light';
  src: url('fonts/gi/Geist-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geist SemiBold';
  src: url('fonts/gi/Geist-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GeistMono Regular';
  src: url('fonts/gi/GeistMono-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
.container {
  cursor: url('fonts/wii-hand-1.cur'), auto;
  min-height: 100vh;
  background-image: repeating-linear-gradient(
    0deg,
    rgb(246, 247, 248) 0px,
    rgb(246, 247, 248) 2.5px,

    rgb(238, 237, 241) 2.5px,
    rgb(244, 245, 247) 3.2px,
    rgb(238, 237, 241) 3.8px,

    rgb(238, 237, 241) 3.9px
  );
  overflow-y: hidden;
  overflow-x: hidden;
  font-family: 'Geist Regular';
  /* padding: env(safe-area-inset-top) env(safe-area-inset-right)
        env(safe-area-inset-bottom) env(safe-area-inset-left); */
}

.container::-webkit-scrollbar {
  display: none;
}

.container::-webkit-scrollbar {
  display: none;
}

.deviceGrid::-webkit-scrollbar {
  display: none;
}

.listView {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.searchWrapper {
  position: relative;
  width: calc(100% - 1.2rem); /* Account for padding */
  margin-bottom: 1rem;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.searchInput {
  width: 100%;
  cursor: url('fonts/wii-hand-blue.cur'), auto;
  padding-left: 40px;
  height: 38px;
  border-radius: 0.48rem;
  border: 1.2px solid #e5e7eb;
  background: linear-gradient(to bottom, rgba(249, 250, 251, 0.95), rgba(243, 244, 246, 0.8));

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  font-family: inherit;
  font-size: 0.88rem;
  box-sizing: border-box;
  transition: all 0.2s;
}

.searchInput:focus {
  border-color: #93c5fd;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
  outline: none;
}

.searchIcon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.12rem;
  height: 1.12rem;
  color: #9ca3af;
  transition: color 0.2s;
}

/* Base card styles */
.deviceCard {
  position: relative;
  cursor: url('fonts/wii-hand-2.cur'), auto;
  border: none;
  background: none;
  text-align: center;
  width: 100%;
  padding: 1.25rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  touch-action: manipulation;
  transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* Enhanced gradient background with shine effect */
.deviceCard::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(120deg, #f5f6f7 0%, #eff0f2 50%, #e9eaec 100%);
  border-radius: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transform: scale(1) translateY(0);
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  border: 1.4px solid rgb(215, 227, 231);
}

/* Gradient border with shine effect */
.deviceCard::after {
  content: '';
  position: absolute;
  inset: -2px;
  background: linear-gradient(120deg, #93c5fd 0%, #60a5fa 45%, #dbeafe 50%, #60a5fa 55%, #3b82f6 100%);
  background-size: 200% 100%;
  border-radius: 1.5rem;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* New: Playful accent decoration */
.deviceCard.online::before {
  /* background: linear-gradient(
        120deg,
        #f5f6f7 0%,
        #eff0f2 50%,
        rgba(219, 234, 254, 0.3) 100%
    ); */
}

/* New: Sparkle effect container */
.sparkleContainer {
  position: absolute;
  inset: -20px;
  pointer-events: none;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Only show sparkles for online devices */
.deviceCard.online:hover .sparkleContainer {
  opacity: 1;
}

.sparkle {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #60a5fa;
  animation: sparkle 1.5s infinite;
}

/* Position sparkles */
.sparkle:nth-child(1) {
  top: 20%;
  left: 10%;
  animation-delay: 0s;
}
.sparkle:nth-child(2) {
  top: 60%;
  right: 10%;
  animation-delay: 0.3s;
}
.sparkle:nth-child(3) {
  bottom: 20%;
  left: 15%;
  animation-delay: 0.6s;
}
.sparkle:nth-child(4) {
  top: 30%;
  right: 15%;
  animation-delay: 0.9s;
}

.deviceCardContent {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.18rem;
}

/* New: Glow effect for online devices */
.deviceCard.online .deviceImageWrapper::after {
  content: '';
  position: absolute;
  inset: -8px;
  border-radius: 50%;
  background: radial-gradient(circle at center, rgba(96, 165, 250, 0.15), transparent 70%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.deviceCard.online:hover .deviceImageWrapper::after {
  opacity: 1;
}

.deviceImage {
  border-radius: 50%;
  transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.deviceNameContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  padding-bottom: 0.25rem;
}

.deviceName {
  font-family: 'Geist Medium';
  letter-spacing: 0.11px;
  color: rgba(31, 41, 55, 0.98);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  position: relative;
  z-index: 1;
  font-size: clamp(0.815rem, 3vw, 1rem);
  margin-bottom: 0.1rem;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.deviceModel {
  font-size: clamp(0.75rem, 2.5vw, 0.875rem);
  font-family: 'GeistMono Regular';
  color: #6b7280;
  letter-spacing: -0.2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
}

/* Enhanced hover states - only for online devices */
.deviceCard.online:hover {
  transform: translateY(-4px);
}

.deviceCard.online:hover::before {
  transform: scale(1.02);
  border-color: transparent;
  box-shadow:
    0 4px 12px rgba(59, 130, 246, 0.15),
    0 0 0 1px rgba(59, 130, 246, 0.1);
}

.deviceCard.online:hover::after {
  opacity: 1;
  animation: shimmer 2s infinite;
}

.deviceCard.online:hover .deviceImage {
  transform: scale(1.08) rotate(-2deg);
}

.deviceCard.online:hover .deviceName {
  transform: translateY(-2px);
  background: linear-gradient(120deg, #2563eb, #3b82f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.deviceCard.online:hover .deviceModel {
  transform: translateY(-1px);
  color: #3b82f6;
}

/* Offline state - more subdued */
.deviceCard.offline {
  opacity: 0.85;
  filter: saturate(0.9);
}

.deviceCard.offline::before {
  background: linear-gradient(120deg, #f5f6f7 0%, #f3f4f6 100%);
}

.deviceCard.offline:hover {
  transform: translateY(-2px);
}

.deviceCard.offline:hover::before {
  transform: scale(1.01);
  border-color: #e5e7eb;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes sparkle {
  0%,
  100% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: scale(1) rotate(180deg);
    opacity: 0.8;
  }
}

/* Touch device optimizations */
@media (hover: none) {
  .deviceCard.online:active {
    transform: scale(0.98);
  }

  .deviceCard.online:active .deviceImageWrapper {
    transform: scale(0.95);
  }

  .deviceCard.online:active::after {
    opacity: 1;
  }

  /* Ensure sparkles work on touch */
  .deviceCard.online:active .sparkleContainer {
    opacity: 1;
  }
}

.deviceImage {
  border-radius: 50%;
}

.placeholderIcon {
  width: 2rem;
  height: 2rem;
  color: #9ca3af;
}

/* Add these to your existing CSS module */
.deviceNameContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.35rem;
  position: relative;
  text-overflow: ellipsis;
}

.deviceName {
  font-family: 'Geist Medium';
  letter-spacing: 0.11px;
  color: rgba(31, 41, 55, 0.98);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  position: relative;
  z-index: 1;
  font-size: clamp(0.815rem, 3vw, 1rem);
}

/* Add a subtle hover effect to the status indicators */
.deviceCard:hover .deviceNameContainer > div {
  transform: translateY(-1px);
  border-color: #93c5fd;
}

/* Transition for smooth animations */
.deviceNameContainer > div {
  transition: all 0.2s ease-out;
}

/* Adjust the device card padding to accommodate the new elements */
.deviceCard {
  padding: 1.25rem 1rem;
}

.deviceState {
  border-radius: 100%;
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  background-color: lightgrey;
  position: relative;
  margin-left: 10px;
  left: 0;
}

.deviceModel {
  font-size: clamp(0.75rem, 2.5vw, 0.875rem);
  font-family: 'GeistMono Regular';
  color: #6b7280;
  letter-spacing: -0.2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
}

.deviceModel span {
  margin-left: 2.2px;
  margin-right: 2.2px;
}

.addIconWrapper {
  width: 4rem;
  height: 4rem;
  margin-bottom: 0.5rem;
  border-radius: 50%;
  background: linear-gradient(to bottom, #eff6ff, #dbeafe);
  border: 2px solid #93c5fd;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: all 0.2s;
}

.addDeviceCard:hover .addIconWrapper {
  border-color: #60a5fa;
  background: linear-gradient(to bottom, #dbeafe, #bfdbfe);
}

.addIcon {
  font-size: 2.5rem;
  color: #3b82f6;
  line-height: 1;
}

.addDeviceText {
  color: #1f2937;
}

.bottomBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: max(6rem, calc(5rem + env(safe-area-inset-bottom)));
  background: linear-gradient(to bottom, rgba(243, 244, 246, 0), rgba(243, 244, 246, 0.85) 40%, rgba(229, 231, 235, 0.95));
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  /* Add a mask-image to fade out the blur effect */
  -webkit-mask-image: linear-gradient(to bottom, transparent, black 40%, black);
  mask-image: linear-gradient(to bottom, transparent, black 40%, black);
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 10;
}

.bottomBarButton,
.addDeviceButton {
  position: absolute;
  bottom: max(1.5rem, calc(1rem + env(safe-area-inset-bottom)));
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: linear-gradient(to bottom, #fefefe, #f3f4f6);
  /* border: 2px solid transparent; */
  border: 1px solid #e5e7eb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
}

.bottomBarButton:first-of-type {
  left: 1.5rem;
}

.addDeviceButton {
  right: 1.5rem;
}

.bottomBarButton:hover,
.addDeviceButton:hover {
  background-color: rgba(255, 255, 255, 0.9);
  border-color: #93c5fd;
}

.bottomBarButton:active,
.addDeviceButton:active {
  transform: scale(0.95);
}

.clockIcon,
.addDeviceIcon {
  width: 1.5rem;
  height: 1.5rem;
  color: #3b82f6;
}

/* Fix for time display overlap */
.timeDisplay {
  position: absolute;
  bottom: max(1.5rem, calc(1rem + env(safe-area-inset-bottom)));
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 1.5rem;
  border-radius: 9999px;
  background: linear-gradient(to bottom, #f9fafb, #f3f4f6);
  border: 1px solid #e5e7eb;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  color: #4b5563;
  font-size: clamp(0.75rem, 3vw, 0.875rem);
  white-space: nowrap;
  font-family: 'Geist Medium';
  z-index: 10;
}

@media (max-width: 400px) {
  .timeDisplay {
    padding: 0.5rem 1rem;
  }

  .timeDivider {
    margin: 0 0.25rem;
  }
}

.timeDivider {
  margin: 0 0.5rem;
}

.bottomBarButton {
  position: absolute;
  bottom: max(1.5rem, calc(1rem + env(safe-area-inset-bottom)));
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: linear-gradient(to bottom, #f9fafb, #f3f4f6);
  border: 1px solid #e5e7eb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
}

.bottomBarButton:first-of-type {
  left: 1.5rem;
}

.bottomBarButton:last-of-type {
  right: 1.5rem;
}

.bottomBarButton:hover {
  background-color: rgba(255, 255, 255, 0.9);
  border-color: #93c5fd;
}

.clockIcon,
.mailIcon {
  width: 1.5rem;
  height: 1.5rem;
  color: #3b82f6;
}

/* Update search styles */

.searchInput:focus {
  border-color: #93c5fd;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
  outline: none;
}

.searchInput:focus + .searchIcon {
  color: #3b82f6;
}

/* Safe area adjustments for notched phones */
@supports (padding: max(0px)) {
  .container {
    /* padding-top: max(1rem, env(safe-area-inset-top)); */
    /* padding-bottom: max(1rem, env(safe-area-inset-bottom)); */
    /* padding-left: max(1rem, env(safe-area-inset-left));
        padding-right: max(1rem, env(safe-area-inset-right)); */
  }

  .bottomBar {
    padding-bottom: max(1.5rem, env(safe-area-inset-bottom));
  }

  .timeDisplay,
  .bottomBarButton {
    bottom: max(1.5rem, calc(1rem + env(safe-area-inset-bottom)));
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .input::placeholder {
    color: #6b7280;
  }
}

.deviceCard:hover .deviceImageWrapper,
.addDeviceCard:hover .deviceImageWrapper {
  border-color: #e5e7eb;
}

/* Fix for device grid centering on mobile */
.deviceGrid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 10rem;
  padding-inline: 1rem;
  margin: 0 auto;
  width: calc(100% - 2rem); /* Account for padding */
  max-width: 1200px;
  justify-content: center;
  padding-top: 4.5rem;
}

@media (max-width: 640px) {
  .deviceGrid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-inline: 0.5rem;
    width: calc(100% - 1rem);
  }
}

@media (max-width: 640px) {
  .deviceGrid {
    padding-top: 4.5rem;

    grid-template-columns: repeat(2, 1fr);
    gap: 0.8rem;
    padding-bottom: 10rem;
    width: calc(100% - 1.6rem);
  }

  /* Force equal widths on mobile */
  .deviceCard {
    width: 100%;
    min-width: 0;
  }

  /* Prevent text overflow causing width issues */
}

.deviceName {
  max-width: 120px; /* or appropriate value */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 auto;
}

/* Enhanced device card animations */
.deviceCard {
  position: relative;
  cursor: url('fonts/wii-hand-2.cur'), auto;
  border: none;
  background: none;
  text-align: center;
  width: 100%;
  padding: 1.25rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  touch-action: manipulation;
  transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  animation: sequentialBounce 28s infinite var(--bounce-delay); /* Reduced from 16s */
}

/* Base card gradient with smoother transitions */
.deviceCard::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.95) 0%, rgba(246, 247, 248, 0.95) 100%);
  border-radius: 1.25rem;
  border: 1.5px solid rgba(215, 227, 231, 0.8);
  transform: scale(1) translateY(0);
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.02),
    0 4px 8px rgba(0, 0, 0, 0.02);
}

/* Only animate when online */
.deviceCard.online .deviceImageWrapper {
  animation: gentleFloat 4s infinite cubic-bezier(0.4, 0, 0.2, 1) 38s;
}

/* Sparkle effects */
.sparkleContainer {
  position: absolute;
  inset: -20px;
  pointer-events: none;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.sparkle {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: linear-gradient(135deg, #60a5fa, #3b82f6);
  animation: sparkle 2s infinite;
}

.sparkle:nth-child(1) {
  top: 20%;
  left: 10%;
  animation-delay: 0s;
}
.sparkle:nth-child(2) {
  top: 60%;
  right: 10%;
  animation-delay: 0.5s;
}
.sparkle:nth-child(3) {
  bottom: 20%;
  left: 15%;
  animation-delay: 1s;
}
.sparkle:nth-child(4) {
  top: 30%;
  right: 15%;
  animation-delay: 1.5s;
}

/* Enhanced hover states */
.deviceCard.online:hover {
  transform: translateY(-4px);
}

.deviceCard.online:hover::before {
  transform: scale(1);
  border-color: transparent;
  box-shadow:
    0 4px 12px rgba(59, 130, 246, 0.15),
    0 0 0 1px rgba(59, 130, 246, 0.1);
}

.deviceCard.online:hover .deviceImageWrapper {
  transform: scale(1.08) rotate(-2deg);
}

.deviceCard.online:hover .sparkleContainer {
  opacity: 1;
}

/* Offline state */
.deviceCard.offline {
  opacity: 0.85;
  filter: saturate(0.9);
}

.deviceCard.offline:hover {
  transform: translateY(-2px);
}

/* Animation Keyframes */
@keyframes cardAppear {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes gentleFloat {
  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-4.8px) rotate(1.8deg);
  }
}

/* Softer sequential bounce
@keyframes sequentialBounce {
    0%,
    97%,
    100% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
    98.5% {
        transform: translateY(-4px);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
} */

@keyframes sparkle {
  0%,
  100% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: scale(1) rotate(180deg);
    opacity: 0.8;
  }
}

/* Touch device optimizations */
@media (hover: none) {
  .deviceCard.online:active {
    transform: scale(0.98);
  }

  .deviceCard.online:active .deviceImageWrapper {
    transform: scale(0.95);
  }

  .deviceCard.online:active .sparkleContainer {
    opacity: 1;
  }
}

/* Consistent device image styling */
.deviceImageWrapper {
  position: relative;
  width: 48px;
  height: 48px;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.deviceImageWrapper::before {
  content: '';
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.95), rgba(243, 244, 246, 0.95));
  box-shadow:
    inset 0 2px 4px rgba(0, 0, 0, 0.05),
    0 2px 4px rgba(59, 130, 246, 0.1);
  z-index: -1;
}

.deviceImageWrapper.kpopImage {
  object-fit: cover;
  width: 58px;
  height: 58px;
}

.deviceImageWrapper.itemImage {
  object-fit: cover;
  width: 58px;
  height: 58px;
}

.deviceImage {
  width: 42px;
  height: 42px;
  object-fit: contain;
  border-radius: 50%;
}

.deviceImage.kpopImage {
  width: 56px;
  height: 56px;
  object-fit: cover;
  border-radius: 50%;
}

.deviceImage.itemImage {
  width: 56px;
  height: 56px;
  object-fit: cover;
  border-radius: 0%;
}

/* Unified Wii-inspired animations for status indicators */
.deviceStateIndicator {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-top: 0.25rem;
}

.statusBadge,
.lastSeenBadge {
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(243, 244, 246, 0.9));
  border: 1px solid rgba(226, 232, 240, 0.8);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Online indicator animation */
.statusDot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 4px;
  transition: all 0.3s ease;
}

.statusDot.online {
  background: linear-gradient(135deg, #10b981, #34d399);
  animation: gentlePulse 2s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.statusDot.offline {
  background: linear-gradient(135deg, #9ca3af, #d1d5db);
}

@keyframes gentlePulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
}

/* Hover effects */
.deviceCard:hover .statusBadge,
.deviceCard:hover .lastSeenBadge {
  transform: translateY(-1px);
  border-color: rgba(147, 197, 253, 0.4);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.98), rgba(243, 244, 246, 0.95));
}

/* Card content layout */
.deviceCardContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.35rem;
}

/* Ensure consistent spacing */
.deviceNameWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
}

.deviceName {
  font-family: 'Geist Medium';
  font-size: 0.875rem;
  color: #1f2937;
  text-align: center;
}

/* Device model text */
.deviceModel {
  font-family: 'GeistMono Regular';
  font-size: 0.75rem;
  color: #6b7280;
  margin-top: 0.25rem;
}

.deviceModel span {
  margin: 0 0.25rem;
  opacity: 0.5;
}

/* Softer sequential bounce */
@keyframes sequentialBounce {
  0%,
  97%,
  100% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  98.5% {
    transform: translateY(-4px);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
}

.deviceLastLocation {
  font-size: 10px;
  color: #6b7280;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  height: 14px;
}

.mapPinIcon {
  color: #6b7280;
  margin-right: 5px;
  width: 9px;
  height: 9px;
}

/* Location grouping layout styles */
.groupedContainer {
  height: 100%;
  overflow-y: auto;
  padding-top: 4.5rem;
  padding-bottom: 1rem;

  width: 100%;
}

.locationGroup {
  margin-bottom: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deviceGrid.grouped {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;

  width: calc(100% - 1.6rem);
}

/* Enhanced header styles */
.locationHeader {
  position: relative;
  border: none;
  background: none;

  cursor: url('fonts/wii-hand-2.cur'), auto;
  margin-bottom: 0.5rem;
  width: auto;
  min-width: 200px;
  display: flex;
  justify-content: center;
}

.headerContent {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 1rem;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(239, 246, 255, 0.95));
  border-radius: 1rem;
  border: 1.2px solid rgba(191, 219, 254, 0.8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  width: 220px;
  max-width: 100%;
}

/* Swirl styles */
.swirlsContainer {
  position: absolute;
  left: -75px;
  right: -75px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  opacity: 0.6;
}

.swirl {
  width: 120px;
  height: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.swirlLeft {
  left: 0;
  background: linear-gradient(90deg, transparent, #1d4ed8);
  mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='24'%3E%3Cpath d='M0 12 C20 12, 20 2, 40 2 M0 12 C20 12, 20 22, 40 22' stroke='black' fill='none' stroke-width='2'/%3E%3C/svg%3E") no-repeat center;
  -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='24'%3E%3Cpath d='M0 12 C20 12, 20 2, 40 2 M0 12 C20 12, 20 22, 40 22' stroke='black' fill='none' stroke-width='2'/%3E%3C/svg%3E") no-repeat center;
  animation: floatLeft 6s ease-in-out infinite;
}

.swirlRight {
  right: 0;
  background: linear-gradient(-90deg, #1d4ed8, transparent);
  transform: translateY(-50%) scaleX(-1);
  mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='24'%3E%3Cpath d='M0 12 C20 12, 20 2, 40 2 M0 12 C20 12, 20 22, 40 22' stroke='black' fill='none' stroke-width='2'/%3E%3C/svg%3E") no-repeat center;
  -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='24'%3E%3Cpath d='M0 12 C20 12, 20 2, 40 2 M0 12 C20 12, 20 22, 40 22' stroke='black' fill='none' stroke-width='2'/%3E%3C/svg%3E") no-repeat center;
  animation: floatRight 6s ease-in-out infinite;
}

.chevron {
  color: #60a5fa;
  transition: transform 0.2s ease;
}

.locationHeader:hover .chevron {
  transform: translateX(2px);
}

.dotDecorations {
  display: flex;
  align-items: center;
  gap: 4px;
}

.dot {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #93c5fd;
  animation: pulseDot 2s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.locationName {
  font-family: 'Geist Medium';
  font-size: 0.875rem;
  color: #1d4ed8;
  white-space: nowrap;
}

.locationCount {
  font-size: 0.75rem;
  padding: 0.25rem 0.75rem;
  background-color: rgba(255, 255, 255, 0.9);
  color: #3b82f6;
  border-radius: 9999px;
  border: 1px solid rgba(147, 197, 253, 0.3);
  position: relative;
  transition: all 0.3s ease;
}

.locationHeader:hover .headerContent {
  transform: translateY(-1px);
  border-color: #93c5fd;
  box-shadow: 0 4px 6px rgba(59, 130, 246, 0.1);
}

.locationHeader:hover .locationCount {
  transform: scale(1.05);
  border-color: #93c5fd;
  background-color: white;
}

.locationCount::after {
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  background: #93c5fd;
  border-radius: 50%;
  top: -1px;
  right: -1px;
  animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
}

/* Animations */
@keyframes ping {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulseDot {
  0%,
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.2;
  }
}

@keyframes floatLeft {
  0%,
  100% {
    transform: translateY(-50%) translateX(0);
    opacity: 0.88;
  }
  50% {
    transform: translateY(-50%) translateX(-18px);
    opacity: 0.2;
  }
}

@keyframes floatRight {
  0%,
  100% {
    transform: translateY(-50%) translateX(0) scaleX(-1);
    opacity: 0.88;
  }
  50% {
    transform: translateY(-50%) translateX(18px) scaleX(-1);
    opacity: 0.2;
  }
}

/* Mobile optimizations */
@media (max-width: 640px) {
  /* .headerContent {
    padding: 0.5rem 0.75rem;
  }

  .deviceGrid.grouped {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  } */
  /* 
  .swirlLeft {
    left: -40px;
    width: 40px;
  }

  .swirlRight {
    right: -40px;
    width: 40px;
  } */
}
