.chevronLeft {
    position: absolute;
    left: 1rem;
    width: 1.5rem;
    height: 1.5rem;
}

.form {
    max-width: 28rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.dropzone {
    position: relative;
    padding: 2rem;
    border-radius: 2rem;
    background-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border: 2px solid #e5e7eb;
    transition: border-color 0.2s;
}

.dragActive {
    border-color: #3b82f6;
}

.fileInput {
    display: none;
}

.imageUploadContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.imagePreviewWrapper {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background: linear-gradient(to bottom, #f9fafb, #f3f4f6);
    border: 2px solid #e5e7eb;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imagePreview {
    border-radius: 50%;
}

.uploadIcon {
    width: 4rem;
    height: 4rem;
    color: #9ca3af;
}

.uploadButton {
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    background-color: #eff6ff;
    color: #3b82f6;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: inherit;
    gap: 0.5rem;
    transition: background-color 0.2s;
}

.uploadButton:hover {
    background-color: #dbeafe;
}

.uploadButtonIcon {
    width: 1rem;
    height: 1rem;
}

.formFields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* Add container padding for narrow screens */
@media (max-width: 640px) {
    .formFields {
        padding: 0 0.5rem;
    }
}

/* Keep all the font declarations and existing styles until .formSection, then replace everything after with these consolidated styles: */

.sectionTitle {
    font-size: 1.125rem;
    color: #1f2937;
    margin-bottom: 1.5rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #e5e7eb;
}

.formField {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.label.required::before {
    content: '* ';
    color: #ef4444;
    margin-left: 0.55rem;
}

/* MAC Address input specific styles */
input[name='macAddress'] {
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Number input styles */
input[type='number'] {
    -moz-appearance: textfield;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.deviceTypeSelector {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 1rem;
}

.deviceTypeIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: 1rem;
    background: linear-gradient(to bottom, #f9fafb, #f3f4f6);
    color: #6b7280;
    transition: all 0.2s;
}

.deviceTypeLabel {
    color: #1f2937;
}

.sectionTitle {
    font-size: 1.125rem;

    color: #1f2937;
    margin-bottom: 1.5rem;
    padding-bottom: 0.75rem;
    font-family: 'Geist SemiBold';
    letter-spacing: 0.1px;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-top: 0;
}

.sectionTitleIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 0.75rem;
    background: linear-gradient(to bottom, #eff6ff, #dbeafe);
    color: #3b82f6;
}

/* Section title with icon */
.sectionTitle {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-family: 'Geist SemiBold';
    font-size: 1.125rem;
    color: #1f2937;
    margin-bottom: 1.75rem;
    padding-bottom: 0.75rem;
    border-bottom: 1.5px solid rgba(226, 232, 240, 0.8);
}

.sectionTitleIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 0.75rem;
    background: linear-gradient(135deg, #eff6ff, #dbeafe);
    color: #3b82f6;
    transform: translateZ(5px);
    transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.formSection:hover .sectionTitleIcon {
    transform: translateZ(15px) scale(1.05);
}

/* Form field styling */
.formField {
    position: relative;
    transform-style: preserve-3d;
}

/* Input styling */
.input {
    width: 90%;
    height: 3rem;
    border-radius: 1.25rem;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.95),
        rgba(249, 250, 251, 0.9)
    );
    border: 1.5px solid rgba(226, 232, 240, 0.8);
    font-family: 'Geist Regular';
    font-size: 0.95rem;
    color: #1f2937;
    transform: translateZ(0);
    transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    box-shadow:
        0 1px 2px rgba(0, 0, 0, 0.02),
        0 2px 4px rgba(0, 0, 0, 0.02);
}

.formField input {
    padding: 0 1rem;
    margin-top: 4px;
}

.input:hover {
    border-color: rgba(147, 197, 253, 0.4);
}

.input:focus {
    outline: none;
    border-color: #93c5fd;
    transform: translateZ(10px);
    box-shadow:
        0 0 0 3px rgba(59, 130, 246, 0.1),
        0 2px 4px rgba(0, 0, 0, 0.02);
}

/* Read-only input styling */
.readOnly {
    background: linear-gradient(to bottom, #f3f4f6, #f9fafb);
    cursor: not-allowed;
    opacity: 0.8;
    height: 20px;
    padding: 0px;
}

/* Checkbox group styling */
.checkboxGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0.5rem;
    transform: translateZ(5px);
}

.checkboxLabel {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    padding: 0.625rem 1rem;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.95),
        rgba(249, 250, 251, 0.9)
    );
    border: 1.5px solid rgba(226, 232, 240, 0.8);
    border-radius: 1rem;
    font-family: 'Geist Regular';
    font-size: 0.875rem;
    color: #4b5563;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.checkboxLabel:hover {
    transform: translateY(-2px);
    border-color: rgba(147, 197, 253, 0.4);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

/* Custom checkbox styling */
.checkbox {
    appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    border: 1.5px solid rgba(226, 232, 240, 0.8);
    border-radius: 0.375rem;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.95),
        rgba(249, 250, 251, 0.9)
    );
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
}

.checkbox:checked {
    background: #3b82f6;
    border-color: #3b82f6;
}

.checkbox:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.75rem;
    height: 0.75rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

/* Submit button styling */
.submitButton {
    width: 100%;
    height: 3.75rem;
    margin-top: 2rem;
    background: linear-gradient(135deg, #3b82f6, #2563eb);
    border: none;
    border-radius: 2rem;
    color: white;
    font-family: 'Geist Bold';
    font-size: 1.125rem;
    cursor: pointer;
    transform-style: preserve-3d;
    transform: translateZ(0);
    transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    box-shadow:
        0 4px 6px rgba(59, 130, 246, 0.1),
        0 2px 4px rgba(59, 130, 246, 0.1);
}

.submitButton:hover {
    transform: translateZ(15px);
    box-shadow:
        0 6px 12px rgba(59, 130, 246, 0.15),
        0 4px 6px rgba(59, 130, 246, 0.1);
}

.submitButton:active {
    transform: translateZ(5px);
}

/* Animations */
@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.progressStep {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1.5px solid rgba(226, 232, 240, 0.8);
    border-radius: 1rem;
    background: white;
    color: #6b7280;
    font-family: 'Geist Regular';
    font-size: 0.875rem;
    white-space: nowrap;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.progressStep.active {
    background: #3b82f6;
    border-color: #2563eb;
    color: white;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px -1px rgba(59, 130, 246, 0.1);
}

.progressStep.completed {
    background: #dbeafe;
    border-color: #93c5fd;
    color: #3b82f6;
}

.formNavigation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(8px);
    border-top: 1px solid rgba(226, 232, 240, 0.8);
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    z-index: 10;
}

.navButton {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 3rem;
    padding: 0 1.5rem;
    border-radius: 1.5rem;
    font-family: 'Geist SemiBold';
    font-size: 0.875rem;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    border: 1.5px solid rgba(226, 232, 240, 0.8);
    background: white;
    color: #6b7280;
}

.navButton.next {
    background: #3b82f6;
    border-color: #2563eb;
    color: white;
}

.navButton.submit {
    background: linear-gradient(135deg, #3b82f6, #2563eb);
    border: none;
    color: white;
}

.navButton:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.navButton:active {
    transform: translateY(0);
}

/* Form layout */
.formWrapper {
    width: 100%;
    height: 100vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: linear-gradient(
        to bottom,
        rgb(246, 247, 248) 0px,
        rgb(246, 247, 248) 2.5px,
        rgb(238, 237, 241) 2.5px,
        rgb(244, 245, 247) 3.2px,
        rgb(238, 237, 241) 3.8px,
        rgb(238, 237, 241) 3.9px
    );
}

.formProgress {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
}

.formProgress::-webkit-scrollbar {
    display: none;
}

.progressStep {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1.5px solid rgba(226, 232, 240, 0.8);
    border-radius: 1rem;
    background: white;
    color: #6b7280;
    font-family: 'Geist Regular';
    font-size: 0.875rem;
    white-space: nowrap;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.progressStep.active {
    background: #3b82f6;
    border-color: #2563eb;
    color: white;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px -1px rgba(59, 130, 246, 0.1);
}

.progressStep.completed {
    background: #dbeafe;
    border-color: #93c5fd;
    color: #3b82f6;
}

.stepIndicator {
    text-align: center;
    font-family: 'GeistMono Regular';
    font-size: 0.875rem;
    color: #6b7280;
    margin-top: 0.5rem;
}

.stepHeader {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
}

.stepIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.75rem;
    background: linear-gradient(135deg, #eff6ff, #dbeafe);
    color: #3b82f6;
}

.stepHeader h2 {
    font-family: 'Geist SemiBold';
    font-size: 1.25rem;
    color: #1f2937;
    margin: 0;
}

.formNavigation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(8px);
    border-top: 1px solid rgba(226, 232, 240, 0.8);
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    z-index: 10;
}

.navButton {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 3rem;
    padding: 0 1.5rem;
    border-radius: 1.5rem;
    font-family: 'Geist SemiBold';
    font-size: 0.875rem;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    border: 1.5px solid rgba(226, 232, 240, 0.8);
    background: white;
    color: #6b7280;
}

.navButton.next {
    background: #3b82f6;
    border-color: #2563eb;
    color: white;
}

.navButton.submit {
    background: linear-gradient(135deg, #3b82f6, #2563eb);
    border: none;
    color: white;
}

.navButton:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.navButton:active {
    transform: translateY(0);
}

/* Responsive adjustments */
@media (min-width: 640px) {
    .formStep {
        padding: 0rem;
    }
}

/* Touch device optimizations */
@media (hover: none) {
    .navButton:hover {
        transform: none;
    }

    .navButton:active {
        transform: scale(0.98);
    }
}

.formTitle {
    margin-left: 1rem;
    font-family: 'Geist SemiBold';
    font-size: 1.25rem;
    color: #1f2937;
}

/* Bottom Navigation */
.formNavigation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(8px);
    border-top: 1px solid rgba(226, 232, 240, 0.8);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 10;
}

.tabContainer {
    display: flex;
    gap: 0.5rem;
    overflow-x: auto;
    padding: 0.5rem;
    margin: -0.5rem;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
}

.tabContainer::-webkit-scrollbar {
    display: none;
}

.tabButton {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.35rem;
    padding: 0.75rem;
    min-width: 4.5rem;
    border: none;
    background: transparent;
    color: #6b7280;
    font-family: 'Geist Regular';
    font-size: 0.75rem;
    transition: all 0.2s;
    cursor: pointer;
}

.tabIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1rem;
    background: white;
    border: 1.5px solid rgba(226, 232, 240, 0.8);
    transition: all 0.2s;
}

.tabButton:hover .tabIcon {
    transform: translateY(-2px);
    border-color: #93c5fd;
}

.tabButton.active {
    color: #3b82f6;
}

.tabButton.active .tabIcon {
    background: linear-gradient(135deg, #eff6ff, #dbeafe);
    border-color: #93c5fd;
    transform: translateY(-2px);
}

.tabHighlight {
    position: absolute;
    bottom: 0;
    left: 25%;
    right: 25%;
    height: 3px;
    background: #3b82f6;
    border-radius: 1.5px;
}

.tabLabel {
    white-space: nowrap;
    font-size: 0.75rem;
}

/* Save Button */
.saveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 3rem;
    padding: 0 1.5rem;
    background: linear-gradient(135deg, #3b82f6, #2563eb);
    border: none;
    border-radius: 1.5rem;
    color: white;
    font-family: 'Geist SemiBold';
    font-size: 0.875rem;
    transition: all 0.2s;
}

.saveButton:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(59, 130, 246, 0.2);
}

.saveButton:active {
    transform: translateY(0);
}

/* Touch Optimizations */
@media (hover: none) {
    .backButton:hover,
    .tabButton:hover .tabIcon,
    .saveButton:hover {
        transform: none;
    }

    .backButton:active,
    .tabButton:active .tabIcon,
    .saveButton:active {
        transform: scale(0.98);
    }
}

.formTitle {
    font-family: 'Geist SemiBold';
    font-size: 1.25rem;
    color: #1f2937;
    margin: 0;
    white-space: nowrap;
}

/* Bottom Navigation Enhancements */
.formNavigation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.8) 20%,
        rgba(255, 255, 255, 0.95)
    );
    backdrop-filter: blur(8px);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 10;
}

.tabContainer {
    display: flex;
    gap: 0.5rem;
    overflow-x: auto;
    padding: 0.5rem;
    margin: -0.5rem;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    mask-image: linear-gradient(
        to right,
        transparent,
        black 2%,
        black 98%,
        transparent
    );
}

.tabContainer::-webkit-scrollbar {
    display: none;
}

.tabButton {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.4rem;
    padding: 0.75rem 0.5rem;
    min-width: 5rem;
    border: none;
    background: transparent;
    color: #6b7280;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    animation: tabAppear 0.3s ease-out forwards;
}

.tabIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 1rem;
    background: white;
    border: 1.5px solid rgba(226, 232, 240, 0.8);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform-style: preserve-3d;
    perspective: 1000px;
}

.tabButton:hover .tabIcon {
    transform: translateY(-2px) rotateX(10deg);
    border-color: #93c5fd;
    box-shadow:
        0 4px 6px -1px rgba(59, 130, 246, 0.1),
        0 2px 4px -1px rgba(59, 130, 246, 0.05);
}

.tabButton.active {
    color: #3b82f6;
}

.tabButton.active .tabIcon {
    background: linear-gradient(135deg, #eff6ff, #dbeafe);
    border-color: #93c5fd;
    transform: translateY(-3px) rotateX(10deg);
    animation: activeTabPulse 3s infinite;
}

.tabHighlight {
    position: absolute;
    bottom: -2px;
    left: 20%;
    right: 20%;
    height: 3px;
    background: #3b82f6;
    border-radius: 1.5px;
    box-shadow: 0 0 8px rgba(59, 130, 246, 0.3);
}

.tabLabel {
    font-family: 'Geist Medium';
    font-size: 0.75rem;
    line-height: 1.2;
    text-align: center;
    max-width: 5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    transition: all 0.2s;
}

.tabButton:hover .tabLabel {
    color: #3b82f6;
}

/* Save Button Enhancement */
.saveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 3rem;
    padding: 0 1.5rem;
    background: linear-gradient(135deg, #3b82f6, #2563eb);
    border: none;
    border-radius: 1.5rem;
    color: white;
    font-family: 'Geist SemiBold';
    font-size: 0.875rem;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 4px rgba(59, 130, 246, 0.1);
}

.saveButton:hover {
    transform: translateY(-2px);
    box-shadow:
        0 4px 6px rgba(59, 130, 246, 0.15),
        0 8px 12px rgba(59, 130, 246, 0.1);
}

/* Animations */
@keyframes tabAppear {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes activeTabPulse {
    0%,
    100% {
        box-shadow: 0 0 0 rgba(59, 130, 246, 0);
    }
    50% {
        box-shadow:
            0 4px 6px rgba(59, 130, 246, 0.1),
            0 0 12px rgba(59, 130, 246, 0.1);
    }
}

@keyframes stepTransition {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Mobile Optimizations */
@media (max-width: 640px) {
    .formHeader {
        padding: 1rem;
    }

    .tabButton {
        min-width: 4.5rem;
    }

    .tabIcon {
        width: 2.5rem;
        height: 2.5rem;
    }

    .tabLabel {
        font-size: 0.7rem;
        max-width: 4.5rem;
    }
}

/* Container Layout */
.formContainer {
    width: 100%;
    height: 100vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(
        to bottom,
        rgb(246, 247, 248) 0px,
        rgb(246, 247, 248) 2.5px,
        rgb(238, 237, 241) 2.5px,
        rgb(244, 245, 247) 3.2px,
        rgb(238, 237, 241) 3.8px,
        rgb(238, 237, 241) 3.9px
    );
    position: relative;
    overflow-y: auto;
}

/* Header */
.formHeader {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.875rem 1rem;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.95),
        rgba(255, 255, 255, 0.85)
    );
    border-bottom: 1.5px solid rgba(226, 232, 240, 0.8);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
    z-index: 20;
    position: relative;
}

.backButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.875rem;
    border: 1.5px solid rgba(226, 232, 240, 0.8);
    border-radius: 1rem;
    background: white;
    color: #6b7280;
    font-family: 'Geist Medium';
    font-size: 0.875rem;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.backButton:hover {
    transform: translateY(-1px) scale(1.02);
    border-color: #93c5fd;
    color: #3b82f6;
    box-shadow: 0 4px 6px -1px rgba(59, 130, 246, 0.1);
}

/* Main Content Area */
.formContent {
    flex: 1;
    overflow-y: auto;
    padding: 0.75rem;
    padding-bottom: 9rem; /* Space for navigation */
    -webkit-overflow-scrolling: touch;
    position: relative;
}

/* Fun scrollbar for desktop */
.formContent::-webkit-scrollbar {
    width: 8px;
}

.formContent::-webkit-scrollbar-track {
    background: transparent;
}

.formContent::-webkit-scrollbar-thumb {
    background: #93c5fd;
    border-radius: 4px;
    border: 2px solid transparent;
    background-clip: padding-box;
}

.formContent::-webkit-scrollbar-thumb:hover {
    background: #60a5fa;
}

/* Form Step Styling */
.formStep {
    position: relative;
    max-width: 720px;
    margin: 0 auto;
    animation: stepAppear 0.3s ease-out;
}

/* Section Styling */
.formSection {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 1.25rem;
    padding: 1.25rem;
    margin-bottom: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    position: relative;
    overflow: hidden;
}

.formSection::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(to right, #93c5fd, #60a5fa);
    opacity: 0;
    transition: opacity 0.2s ease;
}

.formSection:hover::before {
    opacity: 1;
}

/* Form Grid Layout */
.formGrid {
    display: grid;
    gap: 0.75rem;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.label {
    display: block;
    font-family: 'Geist Medium';
    font-size: 0.875rem;
    color: #4b5563;
    padding: 0.25rem 0;
    margin-bottom: 0.375rem;
    transition: color 0.2s ease;
    z-index: 1;
}

/* Bottom Navigation */
.formNavigation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.75rem;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.9) 20%,
        rgba(255, 255, 255, 0.95)
    );
    backdrop-filter: blur(8px);
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    z-index: 10;
}

/* Tab Navigation */
.tabContainer {
    display: flex;
    gap: 0.5rem;
    overflow-x: auto;
    padding: 0.5rem;
    margin: -0.5rem;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    mask-image: linear-gradient(
        to right,
        transparent,
        black 5%,
        black 95%,
        transparent
    );
}

.tabButton {
    position: relative;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.35rem;
    padding: 0.5rem;
    min-width: 4.5rem;
    border: none;
    background: transparent;
    color: #6b7280;
    transition: all 0.2s ease;
    transform-origin: bottom;
}

/* Fun hover animation for tabs */
.tabButton:hover {
    animation: tabWiggle 0.5s ease;
}

.tabIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1rem;
    background: white;
    border: 1.5px solid rgba(226, 232, 240, 0.8);
    transition: all 0.3s ease;
    position: relative;
}

.tabButton.active .tabIcon {
    background: linear-gradient(135deg, #eff6ff, #dbeafe);
    border-color: #93c5fd;
    transform: translateY(-2px);
    animation: activeTabFloat 3s ease-in-out infinite;
}

.tabButton.active .tabIcon::after {
    content: '';
    position: absolute;
    inset: -3px;
    border-radius: inherit;
    background: linear-gradient(135deg, #93c5fd, #60a5fa);
    opacity: 0.2;
    z-index: -1;
    animation: glowPulse 2s ease-in-out infinite;
}

.tabLabel {
    font-family: 'Geist Medium';
    font-size: 0.7rem;
    line-height: 1.2;
    text-align: center;
    max-width: 4.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* Save Button */
.saveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 3rem;
    padding: 0 1.5rem;
    background: linear-gradient(135deg, #3b82f6, #2563eb);
    border: none;
    border-radius: 1.5rem;
    color: white;
    font-family: 'Geist Bold';
    font-size: 0.875rem;
    transition: all 0.2s ease;
    animation: buttonPulse 3s infinite;
}

/* Animations */
@keyframes stepAppear {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* @keyframes tabWiggle {
    0%,
    100% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-3deg);
    }
    75% {
        transform: rotate(3deg);
    }
} */

@keyframes activeTabFloat {
    0%,
    100% {
        transform: translateY(-2px);
    }
    50% {
        transform: translateY(-4px);
    }
}

@keyframes glowPulse {
    0%,
    100% {
        opacity: 0.2;
        transform: scale(1);
    }
    50% {
        opacity: 0.3;
        transform: scale(1.1);
    }
}

@keyframes buttonPulse {
    0%,
    100% {
        box-shadow: 0 4px 6px rgba(59, 130, 246, 0.1);
        transform: scale(1);
    }
    50% {
        box-shadow: 0 6px 12px rgba(59, 130, 246, 0.2);
        transform: scale(1.02);
    }
}

/* Mobile Optimizations */
@media (max-width: 640px) {
    .formContent {
        padding: 0.5rem;
        padding-bottom: 9rem;
    }

    .formSection {
        padding: 1rem;
        margin-bottom: 1.5rem;
    }

    .formGrid {
        gap: 0.5rem;
        grid-template-columns: 1fr;
    }
}

/* Interactive States */
.formSection:focus-within {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.input:focus + .label {
    color: #3b82f6;
}

/* Touch Device Optimizations */
@media (hover: none) {
    .formContent {
        -webkit-overflow-scrolling: touch;
    }

    .saveButton:active {
        transform: scale(0.98);
    }

    .tabButton:active {
        transform: scale(0.95);
    }
}

/* Tab Navigation Animations */
.tabContainer {
    display: flex;
    gap: 0.5rem;
    overflow-x: auto;
    padding: 0.5rem;
    margin: -0.5rem;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    mask-image: linear-gradient(
        to right,
        transparent,
        black 5%,
        black 95%,
        transparent
    );
}

.tabButton {
    position: relative;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.35rem;
    padding: 0.5rem;
    min-width: 4.5rem;
    border: none;
    background: transparent;
    color: #6b7280;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.tabIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1rem;
    background: white;
    border: 1.5px solid rgba(226, 232, 240, 0.8);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: center;
}

/* Smooth hover effect */
.tabButton:hover .tabIcon {
    transform: translateY(-2px);
    border-color: #93c5fd;
    background: linear-gradient(135deg, white, #f5f9ff);
}

/* Active tab animation */
.tabButton.active .tabIcon {
    background: linear-gradient(135deg, #eff6ff, #dbeafe);
    border-color: #93c5fd;
    animation:
        activeTabBounce 0.6s cubic-bezier(0.36, 0, 0.66, 1.3) forwards,
        activeTabFloat 3s ease-in-out infinite;
}

/* Subtle glow effect */
.tabButton.active .tabIcon::before {
    content: '';
    position: absolute;
    inset: -4px;
    background: radial-gradient(circle at center, #93c5fd 0%, transparent 70%);
    border-radius: inherit;
    opacity: 0.2;
    z-index: -1;
    animation: glowPulse 2s ease-in-out infinite;
}

/* Device Type Selection Styling */
.deviceTypeSelector {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 1rem;
    perspective: 1000px;
}

.deviceTypeButton {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem 1rem;
    background: white;
    border: 1.5px solid rgba(226, 232, 240, 0.8);
    border-radius: 1.25rem;
    transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    transform-style: preserve-3d;
    cursor: pointer;
}

.deviceTypeButton::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    background: linear-gradient(135deg, #eff6ff, #dbeafe);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: -1;
}

.deviceTypeButton:hover {
    transform: translateY(-4px) rotateX(10deg);
    border-color: #93c5fd;
}

.deviceTypeButton:hover::before {
    opacity: 0.5;
}

.deviceTypeIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 1rem;
    background: white;
    border: 1.5px solid rgba(226, 232, 240, 0.8);
    transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.deviceTypeButton:hover .deviceTypeIcon {
    transform: translateZ(20px);
    border-color: #93c5fd;
}

.deviceTypeButton.selected {
    border-color: #3b82f6;
    transform: translateY(-6px);
    animation: selectedDeviceType 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.deviceTypeButton.selected::before {
    opacity: 1;
}

.deviceTypeButton.selected .deviceTypeIcon {
    transform: translateZ(30px);
    border-color: #3b82f6;
    animation: iconPop 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* Animations */
@keyframes activeTabBounce {
    0% {
        transform: scale(1) translateY(0);
    }
    40% {
        transform: scale(1.08) translateY(-4px);
    }
    80% {
        transform: scale(0.95) translateY(-2px);
    }
    100% {
        transform: scale(1) translateY(-2px);
    }
}

@keyframes activeTabFloat {
    0%,
    100% {
        transform: translateY(-2px);
    }
    50% {
        transform: translateY(-4px);
    }
}

@keyframes glowPulse {
    0%,
    100% {
        opacity: 0.2;
        transform: scale(1);
    }
    50% {
        opacity: 0.3;
        transform: scale(1.1);
    }
}

@keyframes selectedDeviceType {
    0% {
        transform: translateY(0) scale(1);
    }
    50% {
        transform: translateY(-8px) scale(1.05);
    }
    75% {
        transform: translateY(-4px) scale(0.98);
    }
    100% {
        transform: translateY(-6px) scale(1);
    }
}

@keyframes iconPop {
    0% {
        transform: translateZ(0) scale(1);
    }
    50% {
        transform: translateZ(40px) scale(1.1);
    }
    75% {
        transform: translateZ(25px) scale(0.95);
    }
    100% {
        transform: translateZ(30px) scale(1);
    }
}

/* Device Type Transition */
.formStep {
    animation: stepTransition 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

@keyframes stepTransition {
    0% {
        opacity: 0;
        transform: scale(0.9) translateY(10px);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

/* Touch Device Optimizations */
@media (hover: none) {
    .deviceTypeButton:hover {
        transform: none;
    }

    .deviceTypeButton:active {
        transform: scale(0.98);
    }

    .tabButton:active .tabIcon {
        transform: scale(0.95);
    }
}

/* Device Type Selection Animations */
.deviceTypeButton {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem 1rem;
    background: white;
    border: 1.5px solid rgba(226, 232, 240, 0.8);
    border-radius: 1.25rem;
    transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    transform-style: preserve-3d;
    cursor: pointer;
    transform: translateY(0); /* Explicit initial state */
}

.deviceTypeButton:hover {
    transform: translateY(-4px) rotateX(10deg);
    border-color: #93c5fd;
}

.deviceTypeButton.selected {
    border-color: #3b82f6;
    transform: translateY(-6px); /* Fixed final position */
    animation: selectedDeviceType 0.6s cubic-bezier(0.34, 1.56, 0.64, 1)
        forwards;
}

/* Fixed device type selection animation */
@keyframes selectedDeviceType {
    0% {
        transform: translateY(0) scale(1);
    }
    50% {
        transform: translateY(-8px) scale(1.05);
    }
    75% {
        transform: translateY(-5px) scale(0.98);
    }
    100% {
        transform: translateY(-6px) scale(1); /* Matches final transform */
    }
}

/* Tab Selection Animations */
.tabButton {
    position: relative;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.35rem;
    padding: 0.5rem;
    min-width: 4.5rem;
    border: none;
    background: transparent;
    color: #6b7280;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.tabIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1rem;
    background: white;
    border: 1.5px solid rgba(226, 232, 240, 0.8);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: center;
    position: relative;
}

/* Improved tab selection animations */
.tabButton .tabIcon {
    transform: translateY(0);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.tabButton:hover .tabIcon {
    transform: translateY(-2px);
    border-color: #93c5fd;
    background: linear-gradient(135deg, white, #f5f9ff);
}

.tabButton.active .tabIcon {
    background: linear-gradient(135deg, #eff6ff, #dbeafe);
    border-color: #93c5fd;
    animation: activeTabBounce 0.6s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}

/* Smooth exit animation for previously selected tab */
.tabButton:not(.active) .tabIcon {
    animation: tabExit 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes activeTabBounce {
    0% {
        transform: translateY(0) scale(1);
        background: white;
    }
    40% {
        transform: translateY(-4px) scale(1.08);
        background: linear-gradient(135deg, #eff6ff, #dbeafe);
    }
    80% {
        transform: translateY(-2px) scale(0.95);
        background: linear-gradient(135deg, #eff6ff, #dbeafe);
    }
    100% {
        transform: translateY(-2px) scale(1);
        background: linear-gradient(135deg, #eff6ff, #dbeafe);
    }
}

@keyframes tabExit {
    0% {
        transform: translateY(-2px) scale(1);
        background: linear-gradient(135deg, #eff6ff, #dbeafe);
    }
    100% {
        transform: translateY(0) scale(1);
        background: white;
    }
}

/* Smooth glow transitions */
.tabButton .tabIcon::before {
    content: '';
    position: absolute;
    inset: -4px;
    background: radial-gradient(circle at center, #93c5fd 0%, transparent 70%);
    border-radius: inherit;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s ease;
}

.tabButton.active .tabIcon::before {
    opacity: 0.2;
    animation: glowPulse 2s ease-in-out infinite;
}

@keyframes glowPulse {
    0%,
    100% {
        opacity: 0.2;
        transform: scale(1);
    }
    50% {
        opacity: 0.3;
        transform: scale(1.1);
    }
}

/* Additional helper animations */
@keyframes iconFloat {
    0%,
    100% {
        transform: translateY(-2px);
    }
    50% {
        transform: translateY(-4px);
    }
}

/* Touch Device Optimizations */
@media (hover: none) {
    .deviceTypeButton:hover {
        transform: none;
    }

    .deviceTypeButton:active {
        transform: scale(0.98);
    }

    .deviceTypeButton.selected:active {
        transform: translateY(-6px) scale(0.98);
    }

    .tabButton:active .tabIcon {
        transform: scale(0.95);
    }
}

.macAddressInputWrapper {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 10;
}

.macAddressInput {
    flex: 1;
    padding-right: 40px;
}

.qrScanButton {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.2s;
}

.qrScanButton:hover {
    color: #333;
}

.qrScannerModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.qrScannerContent {
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 500px;
}

.qrScannerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.qrScannerHeader h3 {
    margin: 0;
    font-size: 1.2rem;
}

.qrScannerViewfinder {
    position: relative;
    width: 100%;
    aspect-ratio: 4/3;
    overflow: hidden;
    border-radius: 4px;

    z-index: 10;
}

.qrVideo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scaleX(-1); /* Mirror the video for front-facing camera */
}

.qrCanvas {
    display: none;
}

.scannerOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.scannerTarget {
    width: 200px;
    height: 200px;
    border: 2px solid #fff;
    border-radius: 20px;
    box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.3);
}

.qrScannerInstructions {
    text-align: center;
    margin: 20px 0 0;
    color: #666;
}

.closeButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.2s;
}

.closeButton:hover {
    color: #333;
}

.kpopArtistCard {
    display: flex;
    align-items: center;

    border-radius: 1.25rem;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.95),
        rgba(249, 250, 251, 0.9)
    );
    border: 1.5px solid rgba(226, 232, 240, 0.8);

    color: #1f2937;
    transform: translateZ(0);
    transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    box-shadow:
        0 1px 2px rgba(0, 0, 0, 0.02),
        0 2px 4px rgba(0, 0, 0, 0.02);
}

.kpopArtistSection {
    margin-left: 12px;
}

.kpopArtistSection h3 {
    margin: 0;
    font-family: 'Geist Bold';
    font-size: 1rem;
    margin-bottom: 6px;
}

.kpopArtistSection .groupName {
    margin: 0;
    font-family: 'Geist Bold';
    font-size: 0.9rem;
    margin-top: 4px;
}

.kpopArtistSection p {
    margin: 0;
}

.kpopArtistSection p {
    font-family: 'GeistMono Regular';
    margin-bottom: 4px;
    font-size: 0.7rem;
}

.kpopArtistImg {
    width: 120px;
    height: 120px;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kpopArtistImg img {
    object-fit: cover;
    width: 108px;
    height: 108px;
    border-radius: 1rem;
}

/* Loading state animations */
@keyframes shimmerLoading {
    0% {
        background-position: -200% center;
    }
    100% {
        background-position: 200% center;
    }
}

@keyframes sparkleFloat {
    0%,
    100% {
        transform: translate(0, 0) rotate(0deg);
        opacity: 0.2;
    }
    50% {
        transform: translate(3px, -3px) rotate(180deg);
        opacity: 0.8;
    }
}

/* Button loading state */
.saveButton.isLoading {
    position: relative;
    background: linear-gradient(
        90deg,
        rgba(251, 252, 253, 0.95) 0%,
        rgba(243, 244, 246, 0.9) 25%,
        #bfdbfe 50%,
        rgba(243, 244, 246, 0.9) 75%,
        rgba(251, 252, 253, 0.95) 100%
    );
    background-size: 200% 100%;
    animation: shimmerLoading 2s linear infinite;
}

.saveButton.isLoading .sparkle {
    animation: sparkleFloat 1.5s infinite;
    opacity: 1;
}

.saveButton.isLoading .sparkleContainer {
    opacity: 1;
}
