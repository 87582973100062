.statusContainer {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 2px;
}

.statusBadge {
    position: relative;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 2px 8px;
    border-radius: 12px;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.95),
        rgba(243, 244, 246, 0.95)
    );
    border: 1px solid rgba(227, 229, 233, 0.8);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    /* animation: badgeFloat 3s infinite cubic-bezier(0.4, 0, 0.2, 1); */
}

/* Only apply float animation to online and recently offline badges */
.statusBadgeOnline,
.statusBadgeRecentlyOffline {
    animation: badgeFloat 3s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: relative;
}

.online {
    background: linear-gradient(135deg, #34d399, #10b981);
    animation: onlinePulse 2s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.online::before {
    content: '';
    position: absolute;
    inset: -3px;
    border-radius: 50%;
    background: radial-gradient(
        circle at center,
        rgba(52, 211, 153, 0.4) 0%,
        transparent 70%
    );
    animation: glowPulse 2s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.online::after {
    content: '';
    position: absolute;
    inset: -1px;
    border-radius: 50%;
    background: linear-gradient(135deg, #34d399, #10b981);
    opacity: 0.4;
    animation: ringPulse 2s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.offline {
    background: #d1d5db;
    animation: dimPulse 4s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.statusText {
    font-family: 'GeistMono Regular';
    font-size: 10px;
    letter-spacing: -0.3px;
    /* animation: textFloat 3s infinite cubic-bezier(0.4, 0, 0.2, 1); */
}

/* Only apply text float to online and recently offline status */
.onlineText,
.recentlyOfflineText {
    animation: textFloat 3s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.onlineText {
    color: #047857;
}

.offlineText {
    color: #6b7280;
}

.lastSeen {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 2px 8px;
    border-radius: 12px;
    font-family: 'GeistMono Regular';
    font-size: 10px;
    letter-spacing: -0.3px;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.95),
        rgba(243, 244, 246, 0.95)
    );
    border: 1px solid rgba(227, 229, 233, 0.8);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    /* animation: lastSeenFloat 3s infinite cubic-bezier(0.4, 0, 0.2, 1) 0.2s; */
}

/* Only apply last seen float to online and recently offline states */
.lastSeenOnline,
.lastSeenRecentlyOffline {
    animation: lastSeenFloat 3s infinite cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
}

.lastSeenOnline {
    color: #047857;
}

.lastSeenOffline {
    color: #6b7280;
}

.clockIcon {
    width: 10px;
    height: 10px;
    /* animation: clockSpin 3s infinite cubic-bezier(0.4, 0, 0.2, 1) 0.1s; */
}

/* Only apply clock spin to online and recently offline states */
.lastSeenOnline .clockIcon,
.lastSeenRecentlyOffline .clockIcon {
    animation: clockSpin 3s infinite cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
}

/* Synchronized Wii-style animations */
@keyframes badgeFloat {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-2px);
    }
}

@keyframes onlinePulse {
    0%,
    100% {
        transform: scale(1);
        background: linear-gradient(135deg, #34d399, #10b981);
    }
    50% {
        transform: scale(1.1);
        background: linear-gradient(135deg, #10b981, #059669);
    }
}

@keyframes glowPulse {
    0%,
    100% {
        transform: scale(1);
        opacity: 0.4;
    }
    50% {
        transform: scale(1.5);
        opacity: 0;
    }
}

@keyframes ringPulse {
    0%,
    100% {
        transform: scale(1);
        opacity: 0.3;
    }
    50% {
        transform: scale(1.2);
        opacity: 0;
    }
}

@keyframes textFloat {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-1px);
    }
}

@keyframes lastSeenFloat {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-2px);
    }
}

@keyframes clockSpin {
    0%,
    100% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
}

@keyframes dimPulse {
    0%,
    100% {
        opacity: 0.8;
    }
    50% {
        opacity: 0.6;
    }
}

/* Hover effects */
.deviceCard:hover .statusBadge {
    transform: translateY(-1px) scale(1.02);
    border-color: rgba(147, 197, 253, 0.4);
    animation-play-state: paused;
}

.deviceCard:hover .lastSeen {
    transform: translateY(-1px) scale(1.02);
    border-color: rgba(147, 197, 253, 0.4);
    animation-play-state: paused;
}

/* Touch optimizations */
@media (hover: none) {
    .statusBadge:active,
    .lastSeen:active {
        transform: scale(0.98);
    }
}

/* Mobile optimizations */
@media (max-width: 640px) {
    .statusContainer {
        gap: 4px;
    }

    .statusBadge,
    .lastSeen {
        padding: 2px 6px;
    }
}

.dot.recentlyOffline::before {
    content: '';
    position: absolute;
    inset: -3px;
    border-radius: 50%;
    background: radial-gradient(
        circle at center,
        rgba(239, 68, 68, 0.4) 0%,
        transparent 70%
    );
    animation: glowPulse 1s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.dot.recentlyOffline {
    background: linear-gradient(135deg, #fa2a2a, #fd5555);
    animation: pulseRed 1s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.dot.recentlyOffline::after {
    content: '';
    position: absolute;
    inset: -1px;
    border-radius: 50%;
    background: linear-gradient(135deg, #fa2a2a, #fd5555);
    opacity: 0.5;
    animation: ringPulse 1s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.statusText.recentlyOfflineText {
    color: #d83939;
}

.lastSeen.lastSeenRecentlyOffline {
    color: #ca3939;
}

.statusBadge.statusBadgeRecentlyOffline {
    /* border-color: rgba(239, 68, 68, 0.4); */
}

@keyframes pulseRed {
    0%,
    100% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.8;
        box-shadow: 0 0 10px rgba(239, 68, 68, 0.4);
    }
}
